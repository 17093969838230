import { createAction, props } from '@ngrx/store';

export const startRound = createAction('[Round] Start', props<{ payload: any }>());
export const clearRoundState = createAction('[Round] Reset State');

export const showQuestions = createAction('[Round] Show questions', props<{ payload: any }>());
export const updateAnswers = createAction('[Round] Show questions', props<{ payload: any }>());
export const clearQuestions = createAction('[Round] Clear questions');

export const setPointsToSteal = createAction('[Round] Set points to steal', props<{ payload: number }>());
