import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DictionaryFacade} from '../../+state/dictionary/dictionary.facade';
import {ActivatedRoute, NavigationEnd} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {AudioService} from 'qnm-shared/_services/audio.service';
import {environment} from '../../../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Router} from '@angular/router';
import {AudioFacade} from '@qnm/+state/audio/audio.facade';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AudioService]
})
export class AppComponent implements OnInit, OnDestroy {

  onDestroy: Subject<boolean> = new Subject<boolean>();
  production: boolean = environment.production;
  gtmService = environment.gtmId ? inject(GoogleTagManagerService) : null;

  constructor(public translate: TranslateService,
              private route: ActivatedRoute,
              private audioService: AudioService,
              private audioFacade: AudioFacade,
              private cookieService: CookieService,
              public dictionaryFacade: DictionaryFacade,
              private router: Router) {
    this.dictionaryFacade.loadDictionary();
  }

  ngOnInit() {
    this.pushGTMTag();
    this.checkRouteParams();

    if (localStorage.getItem('audioEnabled') && localStorage.getItem('audioEnabled') === 'false') {
      setTimeout(() => {
        this.audioFacade.setAudio(false);
      }, 500);
    }

    this.audioService.prefetchAudio();
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  checkRouteParams() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => this.processActivation(params));
  }

  private processActivation(params: any) {

    if (params.hasOwnProperty('btag')) {
      this.processBTAG(params.btag);
    }
  }

  processBTAG(btag: string) {
    const expiresTime = moment().add(1, 'days').toDate();

    if (this.cookieService.get('btag') === '') {
      this.cookieService.set('btag', btag, {domain: '.sportowequizy.pl', path: '/', expires: expiresTime, secure: true});
    }
  }

  pushGTMTag() {
    if (environment.gtmId) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }
}
