import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserService} from '@qnm/services/user/user.service';
import {LobbyService} from '@qnm/services/lobby/lobby.service';
import * as lobbyActions from './lobby.actions';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {LobbyResponse} from '@qnm/interfaces/lobby/lobby.interface';
import {of} from 'rxjs';
import {WaitingRoomService} from '@qnm/services/waiting-room/waiting-room.service';

@Injectable()
export class LobbyEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private lobbyService: LobbyService,
    private waitingRoomService: WaitingRoomService
  ) {
  }

  lobbyCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyActions.getLobbyCategories),
      exhaustMap(() => this.waitingRoomService.getCategories()
        .pipe(
          map((categories: any) => lobbyActions.getLobbyCategoriesSuccess({payload: categories})),
          catchError(err => of(lobbyActions.getLobbyCategoriesFailure({error: err})))
        )
      )
    )
  );

  lobbyCategoryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyActions.getLobbyCategoriesParams),
      exhaustMap(action => this.waitingRoomService.getCategoriesParams(action.lobbyConfigurationKey)
        .pipe(
          map((categories: any) => lobbyActions.getLobbyCategoriesParamsSuccess({payload: categories})),
          catchError(err => of(lobbyActions.getLobbyCategoriesParamsFailure({error: err})))
        )
      )
    )
  );

  lobby$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyActions.getLobbiesRequest),
      exhaustMap(() => this.lobbyService.getLobbies()
        .pipe(
          map((lobbies: LobbyResponse) => lobbyActions.getLobbiesRequestSuccess({lobbies})),
          catchError(err => of(lobbyActions.getLobbiesRequestFailure({error: err})))
        )
      )
    )
  );

  joinToLobby$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyActions.joinToLobbyRequest),
      exhaustMap((action) => this.lobbyService.joinToLobby(action.lobby)
        .pipe(
          map(data => lobbyActions.joinToLobbyRequestSuccess({lobby: action.lobby})),
          catchError(err => of(lobbyActions.joinToLobbyRequestFailure({error: err})))
        )
      )
    )
  );

  joinToLobbySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyActions.joinToLobbyRequestSuccess),
      tap(action => {
        localStorage.setItem('currentLobby', action.lobby);
      })
    ), {dispatch: false}
  );
}
