import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {LobbyResponse} from '@qnm/interfaces/lobby/lobby.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomService {
  constructor(private http: HttpClient,
              private router: Router,
              private localize: LocalizeRouterService) {
  }

  getWaitingRooms(lobby: string) {

    let params = new HttpParams().set('type', lobby);

    return this.http.get(environment.apiUrl + '/v1/waiting-room', {withCredentials: true, params: params})
      .pipe(map((response: any) => response));
  }

  getWaitingRoom(id: string) {
    return this.http.get(environment.apiUrl + `/v1/waiting-room/${id}`, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  joinToWaitingRoom(id: string, gameId?: string, waitingRoomPassword?: string) {

    const url = gameId ? `/v1/waiting-room/join-public/${id}/${gameId}` : `/v1/waiting-room/join-public/${id}`;
    const urlWithPassword = waitingRoomPassword ? `${url}/${waitingRoomPassword}` : url;

    return this.http.put(environment.apiUrl + urlWithPassword, {}, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  leaveWaitingRoom(id: string) {
    return this.http.put(environment.apiUrl + `/v1/waiting-room/leave/${id}`, {}, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  getLobbyWaitingRooms(payload) {
    let url = '';
    switch (payload.lobbyId) {
      case 'INSTANT':
        url = '/v1/lobby/game-waiting-room';
        break;
      case 'SCHEDULED_TOURNAMENT':
        url = '/v1/lobby/scheduled-waiting-room';
        break;
      case 'INSTANT_TOURNAMENT':
        url = '/v1/lobby/tournament-waiting-room';
        break;
      default:
        url = '/v1/lobby/game-waiting-room';
        break;
    }

    let param = new HttpParams();
    param = param.append('lobbyId', payload.lobbyId);
    param = param.append('walletCurrency', payload.walletCurrency);
    return this.http.get(environment.apiUrl + url, {withCredentials: true, params: param})
      .pipe(map((response: LobbyResponse) => response));
  }

  getCategories() {
    return this.http.get(environment.apiUrl + `/v1/question/category`, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  getCategoriesParams(lobbyConfigurationKey) {
    return this.http.get(environment.apiUrl + `/v1/config/get/${lobbyConfigurationKey}?disableCache=true`, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  registerToScheduledTournament(tournamentId: string, password?: string) {
    const url = password ? `/v1/waiting-room/register/${tournamentId}/${password}` : `/v1/waiting-room/register/${tournamentId}`;

    return this.http.put(environment.apiUrl + url,{}, {withCredentials: true})
      .pipe(map((response: any) => response));
  }

  getDemoWaitingRoom(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/waiting-room/demo', {withCredentials: true}).pipe(map((response: any) => response));
  }

  getWaitingRoomDetails(waitingRoomId: string) {
    return this.http.get(environment.apiUrl + `/v1/waiting-room/${waitingRoomId}`, {withCredentials: true})
      .pipe(map((response: any) => response));
  }
}
