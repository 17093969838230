import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import {GetActivityStreamResponseInterface} from '@qnm/interfaces/activity-stream/GetActivityStreamResponse.interface';

@Injectable()
export class ActivityStreamService {
  constructor(private http: HttpClient) {
  }

  getActivityStream() {
    return this.http.get(environment.apiUrl + '/v1/activity-stream?limit=10&sort[]=-createdAt', {withCredentials: true})
      .pipe(map((response: GetActivityStreamResponseInterface) => response));
  }
}
