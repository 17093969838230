import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as waitingRoomsActions from '@qnm/+state/lobby-waiting-rooms/lobby-waiting-rooms.actions';
import {catchError, exhaustMap, map, mergeMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UserService} from '@qnm/services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {WaitingRoomService} from '@qnm/services/waiting-room/waiting-room.service';
import {NotificationService} from 'qnm-shared/modules/notifications/services/notification/notification.service';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class LobbyWaitingRoomsEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private waitingRoomsService: WaitingRoomService,
    private router: Router,
    private route: ActivatedRoute,
    private localize: LocalizeRouterService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  getLobbyWaitingRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.getLobbyWaitingRooms),
      exhaustMap((data) => this.waitingRoomsService.getLobbyWaitingRooms({
          lobbyId: data.payload.lobbyId,
          walletCurrency: data.payload.walletCurrency
        })
          .pipe(
            map((waitingRooms: any) => waitingRoomsActions.getLobbyWaitingRoomsSuccess({
                lobby: data.payload.lobbyId,
                rooms: waitingRooms
              })
            ),
            catchError(err => of(waitingRoomsActions.getLobbyWaitingRoomsFailure({error: err})))
          )
      )
    )
  );

  waitingRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.getLobbyWaitingRoomsRequest),
      exhaustMap((data) => this.waitingRoomsService.getWaitingRooms(data.lobby)
        .pipe(
          map((waitingRooms: any) => waitingRoomsActions.getLobbyWaitingRoomsRequestSuccess({
            waitingRooms: waitingRooms,
            lobby: data.lobby
          })),
          catchError(err => of(waitingRoomsActions.getLobbyWaitingRoomsRequestFailure({error: err})))
        )
      )
    )
  );

  waitingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.getWaitingRoomRequest),
      mergeMap((data) => this.waitingRoomsService.getWaitingRoom(data.id)
        .pipe(
          map((waitingRoom: any) => waitingRoomsActions.getWaitingRoomRequestSuccess({id: data.id, room: waitingRoom})),
          catchError(err => of(waitingRoomsActions.getWaitingRoomRequestFailure({error: err})))
        )
      )
    )
  );

  joinToWaitingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.joinToWaitingRoomRequest),
      exhaustMap((data) => this.waitingRoomsService.joinToWaitingRoom(data.id, data.gameId, data.waitingRoomPassword)
        .pipe(
          map((payload) => waitingRoomsActions.joinToWaitingRoomRequestSuccess({
            id: data.id,
            payload: {...payload, ...data}
          })),
          catchError(err => of(waitingRoomsActions.joinToWaitingRoomRequestFailure({error: err})))
        )
      )
    )
  );

  joinToWaitingRoomRequestFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.joinToWaitingRoomRequestFailure),
      tap((err) => {
        console.log('err', err)
        if (err.error.error.reason !== 'invalid_password') {
          let message;

          if (err.error.error.errorLabel === 'error.maintenance_mode_active') {
            message = err.error.error.errorLabel;
          } else {
            message = err.error.error.reason ? err.error.error.reason : 'join.waiting_room.error';
          }

          this.notificationService.notify({
            type: 'danger',
            message,
            parameters: err.error.error.errorLabelParameters ? err.error.error.errorLabelParameters : null
          });

          this.router.navigate([this.localize.translateRoute('/')]);
        }
      })
    ), {dispatch: false}
  );

  // joinToWaitingRoomSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(waitingRoomsActions.joinToWaitingRoomRequestSuccess),
  //     tap((data) => {
  //         if (!data.payload.skipRedirect && data.payload.tournamentId) {
  //           this.router.navigate([this.localize.translateRoute('/tournament/tournament-waiting-room'), data.id, data.payload.tournamentId]);
  //         }
  //
  //         if (!data.payload.skipRedirect && data.payload.gameId) {
  //           this.router.navigate([this.localize.translateRoute('/instant-game/room'), data.id, data.payload.gameId]);
  //         }
  //       }
  //     )
  //   ), {dispatch: false}
  // );

  leaveWaitingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.leaveWaitingRoomRequest),
      exhaustMap((data) => this.waitingRoomsService.leaveWaitingRoom(data.id)
        .pipe(
          map(() => waitingRoomsActions.leaveWaitingRoomRequestSuccess({id: data.id})),
          catchError(err => of(waitingRoomsActions.leaveWaitingRoomRequestFailure({error: err})))
        )
      )
    )
  );

  leaveWaitingRoomSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.leaveWaitingRoomRequestSuccess),
      tap(() => this.router.navigate([this.localize.translateRoute('/')]))
    ), {dispatch: false}
  );

  registerToScheduledTournament$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.registerToScheduledTournamentRequest),
      exhaustMap((data) => this.waitingRoomsService.registerToScheduledTournament(data.roomId, data.password)
        .pipe(
          map(() => waitingRoomsActions.registerToScheduledTournamentRequestSuccess({data})),
          catchError(err => of(waitingRoomsActions.registerToScheduledTournamentRequestFailure({error: err})))
        )
      ))
  );

  registerToScheduledTournamentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.registerToScheduledTournamentRequestSuccess),
      tap((data: any) => {
        this.notificationService.notify({
          type: 'success',
          message: 'scheduled_tournament.register.success'
        });

        this.dialog.closeAll();
        this.router.navigate([this.localize.translateRoute('/tournament/scheduled-tournament-waiting-room'), data.data.roomId, data.data.tournamentId]);
      })
    ), {dispatch: false}
  );

  registerToScheduledTournamentFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(waitingRoomsActions.registerToScheduledTournamentRequestFailure),
      tap((err: any) => {
        if (err.error.error.reason !== 'invalid_password') {
          let message;

          if (err.error.error.errorLabel === 'error.maintenance_mode_active') {
            message = err.error.error.errorLabel;
          } else {
            message = err.error.error.reason ? err.error.error.reason : 'scheduled_tournament.register.error';
          }

          this.notificationService.notify({
            type: 'danger',
            message,
            parameters: err.error.error.errorLabelParameters ? err.error.error.errorLabelParameters : null
          });
        }

        this.dialog.closeAll();
      })
    ), {dispatch: false}
  );
}
