import { Action, createReducer, on } from '@ngrx/store';
import * as fromLobbyWaitingRoomsActions from './lobby-waiting-rooms.actions';
import {
  registerToScheduledTournamentRequestSuccess,
  updateActiveWaitingRoomGameParametersAndPlayers
} from './lobby-waiting-rooms.actions';

export interface LobbyWaitingRoomsState {
  waitingRooms: object;
  activeRoomId: any;
  activeRoom: any;
  activeGameId: any;
  error?: any;
  isLoading: boolean;
  isLoaded: boolean;
  isRegisterToScheduledTournamentRequest: boolean;
}

export const initializeState: LobbyWaitingRoomsState = {
  waitingRooms: {},
  activeRoomId: null,
  activeRoom: null,
  activeGameId: null,
  error: null,
  isLoaded: false,
  isLoading: false,
  isRegisterToScheduledTournamentRequest: false
};

const _lobbyWaitingRoomsReducer = createReducer(
  initializeState,
  on(fromLobbyWaitingRoomsActions.setGameId, (state, gameId) => ({...state, activeGameId: gameId})),
  on(fromLobbyWaitingRoomsActions.getWaitingRoomRequestSuccess,(state, {id, room}) => {
    const tmpWaitingRooms = {...state.waitingRooms};
    if (tmpWaitingRooms[room.lobbyId]) {
      const currencyRooms = tmpWaitingRooms[room.lobbyId].map(d => {
        if (d.id === room.id) {
          return room;
        }
        return d;
      });
      // currencyRooms.push(room);
      tmpWaitingRooms[room.lobbyId] = currencyRooms;
    }
    if (state.activeRoomId && room.playersCollection.length && state.activeRoom.id === id) {
      return {...state, waitingRooms: tmpWaitingRooms, activeRoom: room};
    }
    return {...state, waitingRooms: tmpWaitingRooms};
  }),

  on(fromLobbyWaitingRoomsActions.getLobbyWaitingRooms, state => ({...state, isLoaded: false, isLoading: true, error: null})),
  on(fromLobbyWaitingRoomsActions.getLobbyWaitingRoomsSuccess, (state, payload) => {
    let wr = {...state.waitingRooms};
    wr[payload.lobby] = payload.rooms.waitingRooms;
    return {...state, isLoaded: true, isLoading: false, waitingRooms: wr};
  }),

  on(fromLobbyWaitingRoomsActions.joinToWaitingRoomRequestSuccess, (state, {id}) => {
    return ({...state, activeRoomId: id,  activeRoom: state.waitingRooms[localStorage.getItem('currentLobby')].find( r => r.id === id )})
  }),
  on(fromLobbyWaitingRoomsActions.leaveWaitingRoomRequest, (state, {id}) => ({...state, activeRoomId: null, activeRoom: null})),
  on(fromLobbyWaitingRoomsActions.clearActiveWaitingRoom, (state) => ({...state, activeRoomId: null, activeRoom: null})),
  on(fromLobbyWaitingRoomsActions.updateActiveWaitingRoom, (state, {players}) => {
    let wr = {...state.activeRoom};
    wr.playersCollection = players;
    return {...state, activeRoom: wr};
  }),
  on(fromLobbyWaitingRoomsActions.updateActiveWaitingRoomGameParametersAndPlayers, (state, {payload}) => {
    return {...state, activeRoom: {...state.activeRoom, playersCollection: payload.playersCollection, gameParameters: payload.gameParameters}};
  }),
  on(fromLobbyWaitingRoomsActions.registerToScheduledTournamentRequest, (state => ({...state, isRegisterToScheduledTournamentRequest: true}))),
  on(fromLobbyWaitingRoomsActions.registerToScheduledTournamentRequestSuccess, (state => ({ ...state, isRegisterToScheduledTournamentRequest: false }))),
  on(fromLobbyWaitingRoomsActions.registerToScheduledTournamentRequestFailure, (state => ({ ...state, isRegisterToScheduledTournamentRequest: false })))
);

export function lobbyWaitingRoomsReducer(state: LobbyWaitingRoomsState | undefined, action: Action) {
  return _lobbyWaitingRoomsReducer(state, action);
}
