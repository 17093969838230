import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryState } from './dictionary.reducer';

export const getDictionaryState = createFeatureSelector<DictionaryState>('dictionary');

export const getDictionary = createSelector(
  getDictionaryState,
  (state: DictionaryState) => state.dicts
);

export const getDictById = (id: string) => createSelector(
  getDictionary,
  (state: any) => {
    const entries = state[id] ? state[id] : null;
    return entries ? entries : [];
  }
);
