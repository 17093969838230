import { Injectable } from '@angular/core';
import { NotificationAction } from '../../interfaces/notification-state.interface';
import { NotificationUtilsService } from '../notification-utils/notification-utils.service';
import { NotificationOptions } from '../../interfaces/notification-options.interface';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationQueueService {

    notificationStream: Subject<NotificationAction> = new Subject<NotificationAction>();

    constructor(private utilsService: NotificationUtilsService) {
    }

    addNotification(options: NotificationOptions) {

        const payload: NotificationAction = {
            config: options,
            id: this.utilsService.generateUniqueId(),
            type: 'add'
        };

        this.runQueue(payload);

        this.prepareToRemove(payload);
    }

    runQueue(payload) {
        this.notificationStream.next(payload)
    }

    prepareToRemove(payload: NotificationAction) {

        const type = 'remove';

        payload = { ...payload, type};

        setTimeout(() => this.notificationStream.next(payload), 5000);
    }

}
