<section class="popup">
  <div class="popup__box" >
    <h1 class="popup__title">{{'kyc.info.popup.header' | translate}}</h1>
    <a class="popup__close" (click)="onNoClick()">
      <img src="/assets/images/i-shape-close.svg" alt="Close popup">
    </a>
    <div class="popup__body">
      <p>
        {{ label | translate }}
      </p>
    </div>
    <div class="popup__btn-wrap">
      <div class="form-button form-button--profile" >
        <button class="form-button__btn"  type="button" (click)="onNoClick()">
          <span>{{ data.kycStatus === 'requested' ? ('kyc.info.popup.btn.my_profile' | translate) : ('kyc.info.popup.btn.close' | translate) }}</span>
        </button>
      </div>
    </div>

  </div>
</section>
