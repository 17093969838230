import {createAction, props} from '@ngrx/store';

export const getWallets = createAction('[Wallets] Get wallets');
export const getWalletsSuccess = createAction('[Wallets] Get wallets success', props<{ wallets: any }>());
export const getWalletsFailure = createAction('[Wallets] Get wallets failure', props<{ error: any }>());

export const wsUpdateWallet = createAction('[Wallets] Update wallet balance', props<{ payload: any }>());

export const getBonuses = createAction('[Bonuses] Get bonuses');
export const getBonusesSuccess = createAction('[Bonuses] Get bonuses success', props<{ bonuses: any }>());
export const getBonusesFailure = createAction('[Bonuses] Get bonuses failure', props<{ error: any }>());


export const getPayProStatus = createAction('[PayPro] Request Status', props<{ paymentId: string }>());
export const getPayProStatusSuccess = createAction('[PayPro] Request Status success', props<{ status: string }>());
export const getPayProStatusFailure = createAction('[PayPro] Request Status failure', props<{ error: any }>());
