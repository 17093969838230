import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {approveNotificationRequest} from '@qnm/+state/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotifications() {
    return this.http.get(`${environment.apiUrl}/v1/inbox/notifications`, {withCredentials: true}).pipe(map(response => response));
  }

  approveNotification(data) {
    // tslint:disable-next-line:max-line-length
    return this.http.patch(`${environment.apiUrl}/v1/inbox/notification/${data.notificationId}/approve`, {}, {withCredentials: true}).pipe(map(response => response));
  }
}
