import {createAction, props} from '@ngrx/store';

export const getLobbyWaitingRoomsRequest = createAction('[LobbyWaitingRooms] Requests', props<{ lobby: any }>());

export const getLobbyWaitingRoomsRequestSuccess = createAction(
  '[LobbyWaitingRooms] Success',
  props<{ waitingRooms: any, lobby: any }>());

export const getLobbyWaitingRoomsRequestFailure = createAction(
  '[LobbyWaitingRooms] Failure',
  props<{ error: any }>());


export const getWaitingRoomRequest = createAction('[WaitingRoom] Get Request', props<{ id: any }>());
export const getWaitingRoomRequestSuccess = createAction('[WaitingRoom] Get Success', props<{ id: any, room: any }>());
export const getWaitingRoomRequestFailure = createAction('[WaitingRoom] Get Failure', props<{ error: any }>());

export const joinToWaitingRoomRequest = createAction('[WaitingRoom] Join Request', props<{ id: any, skipRedirect?: boolean, gameId?: string, waitingRoomPassword?: string }>());
export const joinToWaitingRoomRequestSuccess = createAction('[WaitingRoom] Join Success', props<{ id: any, payload?: any }>());
export const joinToWaitingRoomRequestFailure = createAction('[WaitingRoom] Join Failure', props<{ error: any }>());

export const leaveWaitingRoomRequest = createAction('[WaitingRoom] Leave Request', props<{ id: any }>());
export const leaveWaitingRoomRequestSuccess = createAction('[WaitingRoom] Leave Success', props<{ id: any }>());
export const leaveWaitingRoomRequestFailure = createAction('[WaitingRoom] Leave Failure', props<{ error: any }>());

export const getLobbyWaitingRooms = createAction('[Lobby WaitingRooms] Get Lobby WaitingRooms', props<{ payload: any }>());
export const getLobbyWaitingRoomsSuccess = createAction('[Lobby WaitingRooms] Get Lobby WaitingRooms Success', props<{ rooms: any, lobby: any }>());
export const getLobbyWaitingRoomsFailure = createAction('[Lobby WaitingRooms] Get Lobby WaitingRooms Failure', props<{ error: any }>());

export const updateActiveWaitingRoom = createAction('[Update Active Room] WS update active', props<{ players: any }>());
export const updateActiveWaitingRoomGameParametersAndPlayers = createAction('[Update Active Room] WS update game parameters', props<{ payload: any }>());
export const setGameId = createAction('[Update Active Room] Set gameId', props<{ gameId: string }>());
export const clearActiveWaitingRoom = createAction('[Clear Active Room] clear');

export const registerToScheduledTournamentRequest = createAction('[WAITING ROOM] Register To Scheduled Tournament', props<{ roomId: string, tournamentId: string, password: string }>());
export const registerToScheduledTournamentRequestSuccess = createAction('WAITING ROOM Register To Scheduled Tournament Success', props<{data: any}>());
export const registerToScheduledTournamentRequestFailure = createAction('[WAITING ROOM] Register To Scheduled Tournament Failure', props<{ error: any }>());
