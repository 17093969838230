import { Action, createReducer, on } from '@ngrx/store';
import * as fromRegisterStore from './register-complete.actions';

export interface RegisterCompleteState {
  isLoading: boolean;
  data: any;
  error?: any;
}

export const initialState: RegisterCompleteState = {
  isLoading: false,
  data: null,
  error: null
};

const _registerCompleteReducer = createReducer(
  initialState,
  on(fromRegisterStore.registerCompleteRequest, state => ({...state, isLoading: true, error: null})),
  on(fromRegisterStore.registerCompleteRequestSuccess, state => ({...state, isLoading: false, error: null})),
  on(fromRegisterStore.registerCompleteRequestFailure, (state, {error}) => ({...state, isLoading: false, error: error})),
);

export function registerCompleteReducer(state: RegisterCompleteState | undefined, action: Action) {
  return _registerCompleteReducer(state, action);
}
