import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserState } from '@qnm/+state/user/user.reducer';
import * as userActions from './user.actions';
import {
  nickNameParams,
  unreadMessagesCount,
  userBankAccounts,
  userData,
  userError,
  userIsLoading,
  userIsLoggedIn,
  userState,
  userVerifiedBankAccounts
} from '@qnm/+state/user/user.selectors';

@Injectable()
export class UserFacade {

  userState$ = this.store.pipe(select(userState));
  user$ = this.store.pipe(select(userData));
  userBankAccounts$ = this.store.pipe(select(userBankAccounts));
  verifiedUserBankAccounts$ = this.store.pipe(select(userVerifiedBankAccounts));
  error$ = this.store.pipe(select(userError));
  loading$ = this.store.pipe(select(userIsLoading));
  isLoggedIn$ = this.store.pipe(select(userIsLoggedIn));
  unreadMessagesCount$ = this.store.pipe(select(unreadMessagesCount));
  nickNameParams$ = this.store.pipe(select(nickNameParams));

  constructor(private store: Store<UserState>) {
  }

  getUser() {
    this.store.dispatch(userActions.loadUser());
  }

  logout() {
    this.store.dispatch(userActions.userLogout());
  }

  logoutSuccess() {
    this.store.dispatch(userActions.userLogoutSuccess());
  }

  uploadAvatar(avatar) {
    this.store.dispatch(userActions.uploadUserAvatar({payload: avatar}));
  }

  updateUser(user) {
    this.store.dispatch(userActions.updateUserData({payload: user}));
  }

  deleteAvatar() {
    this.store.dispatch(userActions.deleteUserAvatar());
  }

  changeUserPassword(payload) {
    this.store.dispatch(userActions.changeUserPassword({payload}));
  }

  updateUserAgreements(payload) {
    this.store.dispatch(userActions.updateUserAgreements({payload}));
  }

  updateUserLocale(payload) {
    this.store.dispatch(userActions.updateUserLocale({payload}));
  }

  updateUserData(payload) {
    this.store.dispatch(userActions.updateUserData({payload}));
  }

  updateUserPersonalData(payload) {
    this.store.dispatch(userActions.updateUserPersonalData({payload}));
  }

  getUserBankAccounts() {
    this.store.dispatch(userActions.getUserBankAccounts());
  }

  getVerifiedUserBankAccounts() {
    this.store.dispatch(userActions.getVerifiedUserBankAccounts());
  }

  getUnreadMessagesCount() {
    this.store.dispatch(userActions.getUnreadMessagesCount());
  }

  getNickNameParameters(): void {
    this.store.dispatch(userActions.getNickNameParameters());
  }

  updateUserNickName(payload: string): void {
    this.store.dispatch(userActions.updateUserNickName({payload}));
  }

  disableUserManual(): void {
    this.store.dispatch(userActions.disableShowManual());
  }
}
