import { User } from '@qnm/interfaces/user.interface';
import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from './user.actions';
import {BankAccountDTO} from '@qnm/interfaces/bank-account/bank-account.interface';
import {NickNameParameters} from 'qnm-auth/interfaces/nick-name.interface';

export interface UserState {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: User;
  bankAccounts: BankAccountDTO[];
  verifiedBankAccounts: BankAccountDTO[];
  unreadMessages: number;
  nickNameParams: NickNameParameters;
  error?: any;
}

export const initialState: UserState = {
  isLoggedIn: false,
  isLoading: false,
  bankAccounts: [],
  verifiedBankAccounts: [],
  user: null,
  unreadMessages: 0,
  nickNameParams: null,
  error: null
};

const _userReducer = createReducer(
  initialState,
  on(userActions.loadUser, state => ({...state, isLoading: true, error: null})),
  on(userActions.loadUserSuccess, (state, {user}) => ({...state, user, isLoading: false, isLoggedIn: true, error: null})),
  on(userActions.updateUserAgreementsSuccess, (state, payload) => ({...state, user: { ...state.user, agreement3: payload.payload.agreement3, agreement4: payload.payload.agreement4 } , isLoading: false, isLoggedIn: true, error: null})),
  on(userActions.updateUserLocaleSuccess, (state, payload) => ({...state, user: { ...state.user, locale: payload.payload.locale }, isLoading: false, isLoggedIn: true, error: null})),
  on(userActions.loadUserFailure, (state, {error}) => ({...state, user: null, isLoading: false, isLoggedIn: false, error})),
  on(userActions.updateUserData, (state) => ({...state, isLoading: true})),
  on(userActions.updateUserDataSuccess, (state) => ({...state, isLoading: false})),
  on(userActions.updateUserDataFailure, (state) => ({...state, isLoading: false})),
  on(userActions.changeUserPassword, (state) => ({...state, isLoading: true, error: null})),
  on(userActions.changeUserPasswordSuccess, (state) => ({...state, isLoading: false, error: null})),
  on(userActions.changeUserPasswordFailure, (state, error) => ({...state, isLoading: false, error: error.error.error})),
  on(userActions.getUserBankAccountsSuccess, (state, payload) => ({...state, isLoading: false, bankAccounts: payload.payload})),
  on(userActions.getVerifiedUserBankAccountsSuccess, (state, payload) => ({...state, isLoading: false, verifiedBankAccounts: payload.payload})),
  on(userActions.getUnreadMessagesCountSuccess, (state, payload) => ({...state, unreadMessages: payload.payload.count})),
  on(userActions.getNickNameParametersSuccess, (state, payload) => ({...state, nickNameParams: payload.payload})),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}
