import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LayoutPartialState } from './layout.reducer';
import { layoutQuery } from './layout.selectors';
import {
    ChangeTheme,
    CloseSideBarMenu,
    CollapseSidebar,
    OpenSideBarMenu,
    ResizeWindow,
    SetBodyClass,
    ToggleSideBarMenu,
    HideNavigation,
    ShowNavigation, ShowLoader, HideLoader, ToggleIsFreeCoins, ToggleNavigation
} from './layout.actions';
import { take } from 'rxjs/operators';


@Injectable()
export class LayoutFacade {
  collapsedMenu$ = this.store.pipe(select(layoutQuery.getCollapseMenu));
  isMobileView$ = this.store.pipe(select(layoutQuery.isMobile));
  breakpoint$ = this.store.pipe(select(layoutQuery.getBreakpoint));
  isSidebarExpanded$ = this.store.pipe(select(layoutQuery.isSidebarExpanded));
  bodyClass$ = this.store.pipe(select(layoutQuery.getBodyClass));
  theme$ = this.store.pipe(select(layoutQuery.theme));
  navBar = this.store.pipe(select(layoutQuery.navBar));
  loader$ = this.store.pipe(select(layoutQuery.loader));
  isFreeCoins$ = this.store.pipe(select(layoutQuery.isFreeCoins));

  constructor(private store: Store<LayoutPartialState>) {
  }

  collapseMenu(menu) {
    this.store.dispatch(new CollapseSidebar(menu));
  }

  resizeWindow(obj) {
    this.store.dispatch(new ResizeWindow(obj));
  }

  toggleSidebar() {
    this.store.dispatch(new ToggleSideBarMenu());
  }

  closeSidebar() {
    this.store.dispatch(new CloseSideBarMenu());
  }

  openSidebar() {
    this.store.dispatch(new OpenSideBarMenu());
  }

  setBodyClass(cl) {
    this.store.dispatch(new SetBodyClass(cl));
  }

  toggleIsFreeCoins() {
    this.store.dispatch(new ToggleIsFreeCoins());
  }

  toggleTheme() {
    this.theme$
      .pipe(take(1))
      .subscribe((event: string) => {
        if (event === 'exp-dark-theme') {
          this.store.dispatch(new ChangeTheme({'theme': 'exp-light-theme'}));
        } else {
          this.store.dispatch(new ChangeTheme({'theme': 'exp-dark-theme'}));
        }
      });
  }

  hideNavBar() {
    this.store.dispatch(new ShowNavigation());
  }

  showNavBar() {
    this.store.dispatch(new HideNavigation());
  }

  showLoader() {
    this.store.dispatch(new ShowLoader());
  }

  hideLoader() {
    this.store.dispatch(new HideLoader());
  }

  toggleMobileMenu() {
      this.store.dispatch(new ToggleNavigation())
  }

}
