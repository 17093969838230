import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {LoginRequest, LoginResponse} from '../interfaces/login.interface';
import {RegisterRequest, RegisterResponse} from '../interfaces/register.interface';
import {WebHttpUrlEncodingCodec} from '@qnm/services/token/token.service';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  logIn(loginRequest: LoginRequest) {
    return this.http.post(environment.apiUrl + '/v1/client-security/user/login', loginRequest).pipe(
      map((res: LoginResponse) => {
        return res;
      }));
  }

  register(register: RegisterRequest) {
    return this.http.post(environment.apiUrl + '/v1/client-security/user', {...register}).pipe(
      map((response: RegisterResponse) => response));
  }

  activate(activateCode: any) {
    return this.http.get(
      environment.apiUrl + `/v1/client-security/user/activate/${activateCode}`,
    ).pipe(map((response: any) => response));
  }

  resetPassword(email: any) {
    const params = new HttpParams({encoder: new WebHttpUrlEncodingCodec()}).set('email', email);
    return this.http.get(
      environment.apiUrl + `/v1/client-security/user/reset-password/generate-email-token`, {params}
    ).pipe(map((response: any) => response));
  }

  changePassword(payload: any) {
    return this.http
      .post(`${environment.apiUrl}/v1/client-security/user/reset-password/change`, payload)
      .pipe(
        map((res) => res)
      );
  }

  validateEmailToken(token: string) {
    let param = new HttpParams();
    param = param.append('tokenValue', token);
    return this.http
      .get(`${environment.apiUrl}/v1/client-security/user/reset-password/validate-email-token`, {params: param})
      .pipe(
        map((res: any) => res)
      );
  }

  unlockAccountValidateEmailToken(token: string) {
    let param = new HttpParams();
    param = param.append('tokenValue', token);
    return this.http
      .get(`${environment.apiUrl}/v1/client-security/user/unlock-account/validate-email-token`, {params: param})
      .pipe(
        map((res: any) => res)
      );
  }

  unlockAccountGenerateEmailToken(email: any) {
    const params2 = new HttpParams({encoder: new WebHttpUrlEncodingCodec()}).set('email', email);
    return this.http.get(
      environment.apiUrl + `/v1/client-security/user/unlock-account/generate-email-token`, {params: params2}
    ).pipe(map((response: any) => response));
  }

  isLoggedIn() {
    return !!localStorage.getItem('jwtToken');
  }

  getToken() {
    return localStorage.getItem('jwtToken');
  }

  getPayProStatus(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/purchase/pay-pro/${id}/status`, {withCredentials: false}).pipe(
      map((response: any) => response));
  }

  getUrlToLoginByProvider(payload: any): Observable<any> {
    let param = new HttpParams();
    if (payload.btag) {
      param = param.append('btag', payload.btag);
    }

    return this.http.get(environment.apiUrl + `/v1/client-security/user/auth-provider/${payload.provider}/url`,
      {withCredentials: false, params: param})
      .pipe(map((response: any) => response));
  }

  loginByProvider(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/v1/client-security/user/auth-provider/login`, payload.payload).pipe(
      map((response: any) => response));
  }

  getLoginProviders(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/client-security/user/auth-provider').pipe(
      map((response: any) => response));
  }
}
