import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AudioState} from '@qnm/+state/audio/audio.reducer';
import * as audioActions from './audio.actions';
import {getIsEnable} from '@qnm/+state/audio/audio.selectors';

@Injectable()
export class AudioFacade {
  isEnable$ = this.store.pipe(select(getIsEnable));

  constructor(private store: Store<AudioState>) {
  }

  setAudio(data: boolean) {
    this.store.dispatch(audioActions.setAudio({ isEnable: data }));
  }
}
