import { Action, createReducer, on } from '@ngrx/store';
import * as dictActions from './dictionary.actions';

export interface DictionaryState {
  dicts: any;
  error: any;
  loading: boolean;
  loaded: boolean;
}

export const initDictionaryState: DictionaryState = {
  dicts: null,
  error: null,
  loaded: false,
  loading: false
};

const _dictionaryReducer = createReducer(
  initDictionaryState,
  on(dictActions.loadDictionary, state => ({...state, loaded: false, loading: true, error: null})),
  on(dictActions.loadDictionarySuccess, (state, payload) => ({...state, dicts: payload.payload, loaded: true, loading: false, error: null})),
  on(dictActions.loadDictionaryFailure, (state, payload) => ({...state, loading: false, loaded: true, error: payload.error}))
);

export function dictionaryReducer(state: DictionaryState | undefined, action: Action) {
  return _dictionaryReducer(state, action);
}
