import { Action, createReducer, on } from '@ngrx/store';
import * as gameActions from './game.actions';
import {tournamentId} from '@qnm/+state/game/game.selectors';

export interface GameState {
  gameId: any;
  tournamentId: any;
  playersCollection: any;
  numberOfStages?: any;
  summary: any;
  status?: any;
}

export const initialGameState: GameState = {
  gameId: null,
  tournamentId: null,
  summary: null,
  playersCollection: null
};

const _gameReducer = createReducer(initialGameState,
  on(gameActions.leaveTournamentRequestSuccess, (state, tournamentId) => ({ ...state, tournamentId})),
  on(gameActions.setTournamentId, (state, tournamentId) => ({ ...state, tournamentId})),
  on(gameActions.setTournament, (state, payload) => ({ ...state, tournamentId: payload.payload})),
  on(gameActions.startGame, (state, {game}) => ({...state, ...game})),
  on(gameActions.clearActiveGameState, (state) => ({...initialGameState})),
  on(gameActions.showSummary, (state, {summary}) => ({...state, summary})),
  on(gameActions.updateNumberOfStages, (state, {numberOfStages}) => ({...state, numberOfStages})),
  on(gameActions.updatePlayersStatuses, (state, payload) => {

    const players = state.playersCollection.map(p => {
          const findPlayer = payload.statuses.statuses.find(fp => fp.playerId === p.id);
          if (findPlayer) {
            const newPlayer = {...p, status: findPlayer.status};
            return newPlayer;
          } else {
            return p;
          }
    });

    return {...state, playersCollection: players};
  }),
    on(gameActions.leaveGame, () => ({...initialGameState}))
  );

export function gameReducer(state: GameState | undefined, action: Action) {
  return _gameReducer(state, action);
}


