import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { WSData } from '@qnm/interfaces/web-sockets/ws-data.type';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    /**
     * Web socket object.
     */
    ws: WebSocket;
    /**
     * The subject object which is called when the server sends a message via the WebSocket.
     */
    wsMessage: Subject<WSData> = new Subject();

    constructor() {
    }

    /**
     * Creates a new connection to the WebSocket and sets all methods & events.
     */
    connect(id: string): Observable<void> {
        // Get JWT token from the local storage to create url.
        const token = localStorage.getItem('jwtToken');
        const tenantId = environment.tenantId;
        const url = `${environment.wsUrl}?playerId=${id}&tenantId=${tenantId}`;

        // Interval id from a 'ping' function described below.
        // Interval should be cleared on lose connection.
        let pingIntervalHandle = null;

        return Observable.create(observer => {

            // Connect to websocket.
            this.ws = new WebSocket(url);

            this.ws.onopen = () => {

                // Run ping function on connect to keep the connection.
                pingIntervalHandle = setInterval(() => this.ping(), 5000);

                observer.next(true);
            };

            this.ws.onmessage = (e: MessageEvent) => {
                // Catch every message from the server and pass to the function where the message will be recognized.
                this.onMessage(JSON.parse(e.data));
            };

            this.ws.onerror = () => {
                observer.error();
            };

            this.ws.onclose = () => {

                // Clear interval when web socket connection closes.
                if (pingIntervalHandle) {
                    clearInterval(pingIntervalHandle);
                }

                observer.error();
                observer.complete();
            };

        });
    }

    /**
     * Sends an empty message to the server only to keep the connection.
     */
    ping(): void {

        const payload: string = JSON.stringify({
            cmd: 'ping'
        });

        this.ws.send(payload);
    }

    /**
     * Handles messages received from the web socket connection and add the data to the 'wsMessage' subject.
     * @param data - Data object from the server.
     */
    onMessage(data: WSData): void {
        this.wsMessage.next(data);
    }

    close(): Observable<void> {
      return of(this.ws.close());
    }

    send(dataToSend): void {
      const payload: string = JSON.stringify(dataToSend);

      this.ws.send(payload);
    }

}
