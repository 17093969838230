import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'qnm-kyc-info-modal',
  templateUrl: './kyc-info-modal.component.html',
  styleUrls: ['./kyc-info-modal.component.scss']
})
export class KycInfoModalComponent implements OnInit {
  label: string;

  constructor(public dialogRef: MatDialogRef<KycInfoModalComponent>,
              private router: Router,
              private localize: LocalizeRouterService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.label = `kyc.info.popup.kyc_status_changed_to_${this.data.kycStatus}`;
  }

  onNoClick(): void {
    if (this.data.kycStatus === 'requested') {
      this.router.navigate([this.localize.translateRoute('/settings/profile-details')]);
    }
    this.dialogRef.close();
  }
}
