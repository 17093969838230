import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {WebSocketService} from '@qnm/services/web-socket/web-socket.service';
import * as webSocketActions from '@qnm/+state/web-socket/web-socket.actions';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {NotificationService} from 'qnm-shared/modules/notifications/services/notification/notification.service';
import {UserFacade} from '@qnm/+state/user/user.facade';

@Injectable()
export class WebSocketEffects {
  timeout;

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private wsService: WebSocketService,
    private userFacade: UserFacade
  ) {
  }

  WSconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(webSocketActions.WSconnect),
      concatLatestFrom(() => this.userFacade.user$),
      map(([ss, state]) => {
        return [ss, state];
      }),
      concatMap((act: any) => this.wsService.connect(act[1].id)
        .pipe(
          map(() => {
            this.onWSConnection(act[0].payload);
            return webSocketActions.WSconnectSuccess();
          }),
          catchError(err => {
              this.onWSDisconnection();
              return of(webSocketActions.WSconnectFailure());
            }
          )
        )
      )
    )
  );


  WSclose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(webSocketActions.WSclose),
      concatMap(() => this.wsService.close()
        .pipe(
          map(() => webSocketActions.WScloseSuccess()),
          catchError(err => of(webSocketActions.WScloseFailure())
          )
        )
      )
    )
  );

  onWSConnection(payload: boolean) {
    // If the payload equals true, show a notification for a user that he is connected again.
    if (payload === true) {
      clearTimeout(this.timeout);
      this.notificationService.notify({
        type: 'success',
        message: 'websocket.stabilized.connection'
      });
    }

  }

  onWSDisconnection() {

    const token: string = localStorage.getItem('jwtToken');

    if (!token) {
      return;
    }

    // When it failed to connect or connection was lost, try again to connect for 5 seconds.
    this.timeout = setTimeout(() => {
      // Pass 'true' to create a notification to user on connection success.
      this.store.dispatch(webSocketActions.WSconnect({payload: true}));
    }, 5000);

  }

}
