import { createAction, props } from '@ngrx/store';
import { TokenResponse } from '@qnm/interfaces/token/token.interface';

export const generateToken = createAction('[Token] Generate Token', props<{ type?: any, phone?: string }>());
export const generateTokenSuccess = createAction('[Token] Generate Token Success', props<{ payload: TokenResponse }>());
export const generateTokenFailure = createAction('[Token] Generate Token Failure', props<{ error: any }>());


export const regenerateToken = createAction('[Token] ReGenerate Token', props<{ type?: any, phone?: string }>());
export const regenerateTokenSuccess = createAction('[Token] ReGenerate Token Success', props<{ payload: TokenResponse }>());
export const regenerateTokenFailure = createAction('[Token] ReGenerate Token Failure', props<{ error: any }>());
