import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserFacade } from '@qnm/+state/user/user.facade';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private userFacade: UserFacade, private router: Router, private localize: LocalizeRouterService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap(event => {
    }, err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.userFacade.logoutSuccess();
      }

      if (err instanceof HttpErrorResponse && err.status === 403 && err.error.label === 'required_agreements_not_accepted') {
        this.router.navigate([this.localize.translateRoute('/register-by-provider-agreements')]);
      }
    }));
  }
}
