import {createAction, createSelector} from '@ngrx/store';
import { getQnMState, QnMState } from '@qnm/+state';
import { UserState } from '@qnm/+state/user/user.reducer';

export const userState = createSelector(getQnMState, (state: QnMState) => state.user);
export const userData = createSelector(userState, (state: UserState) => state.user);
export const userError = createSelector(userState, (state: UserState) => state.error);
export const userIsLoggedIn = createSelector(userState, (state: UserState) => state.isLoggedIn);
export const userIsLoading = createSelector(userState, (state: UserState) => state.isLoading);
export const userBankAccounts = createSelector(userState, (state: UserState) => state.bankAccounts);
export const userVerifiedBankAccounts = createSelector(userState, (state: UserState) => state.verifiedBankAccounts);
export const unreadMessagesCount = createSelector(userState, (state: UserState) => state.unreadMessages);
export const nickNameParams = createSelector(userState, (state: UserState) => state.nickNameParams);
