import {createAction, props} from '@ngrx/store';
import {Lobbies, LobbyResponse} from '@qnm/interfaces/lobby/lobby.interface';


export const getLobbiesRequest = createAction('[Lobbies] Get Lobbies Requests');
export const getLobbiesRequestSuccess = createAction('[Lobbies] Get Lobbies Success', props<{ lobbies: LobbyResponse }>());
export const getLobbiesRequestFailure = createAction('[Lobbies] Get Lobbies Failure', props<{ error: any }>());

export const joinToLobbyRequest = createAction('[Join Lobby] Join Lobby Request', props<{ lobby: any }>());
export const joinToLobbyRequestSuccess = createAction('[Join Lobby] Join Lobby Success', props<{ lobby: any }>());
export const joinToLobbyRequestFailure = createAction('[Join Lobby] Join Lobby Failure', props<{ error: any }>());

export const WsUpdateLobby = createAction('[UpdateLobby] Update Lobby', props<{ lobby: Lobbies }>());


export const getLobbyCategories = createAction('[Lobby Categories] get');
export const getLobbyCategoriesSuccess = createAction('[Lobby Categories] get success', props<{ payload: any }>());
export const getLobbyCategoriesFailure = createAction('[Lobby Categories] get failure', props<{ error: any }>());


export const getLobbyCategoriesParams = createAction('[Lobby Categories Params] get', props<{ lobbyConfigurationKey: string }>());
export const getLobbyCategoriesParamsSuccess = createAction('[Lobby Categories Params] get success', props<{ payload: any }>());
export const getLobbyCategoriesParamsFailure = createAction('[Lobby Categories Params] get failure', props<{ error: any }>());
