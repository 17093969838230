import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LAYOUT_FEATURE_KEY, LayoutState } from './layout.reducer';

// Lookup the 'Layout' feature state managed by NgRx
const getLayoutState = createFeatureSelector<LayoutState>(LAYOUT_FEATURE_KEY);

const getCollapseMenu = createSelector(
  getLayoutState,
  (state: LayoutState) => state.collapsedMenu
);

const isMobile = createSelector(
  getLayoutState,
  (state: LayoutState) => state.isMobileView
);

const getBreakpoint = createSelector(
  getLayoutState,
  (state: LayoutState) => state.breakpoint
);


const getBodyClass = createSelector(
  getLayoutState,
  (state: LayoutState) => state.bodyClass
);

const isSidebarExpanded = createSelector(
  getLayoutState,
  (state: LayoutState) => state.isSidebarExpanded
);

const theme = createSelector(
  getLayoutState,
  (state: LayoutState) => state.themeClass
);

const navBar = createSelector(
  getLayoutState,
  (state: LayoutState) => state.hiddenNavBar
);

const loader = createSelector(
  getLayoutState,
  (state: LayoutState) => state.showLoader
);

const isFreeCoins = createSelector(
  getLayoutState,
  (state: LayoutState) => state.isFreeCoins
);


export const layoutQuery = {
  getCollapseMenu,
  isMobile,
  getBreakpoint,
  isSidebarExpanded,
  getBodyClass,
  theme,
  navBar,
  loader,
  isFreeCoins
};
