import { Injectable } from '@angular/core';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { TokenResponse } from '@qnm/interfaces/token/token.interface';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient,
              private router: Router,
              private localize: LocalizeRouterService) {
  }

  generateToken(phone?: string, type?: string): Observable<TokenResponse> {

    let params = new HttpParams().set('type', type ? type : 'phone_number_confirmation');
    if (phone) {
      params = params.append('phone', phone)
    }

    return this.http.get(environment.apiUrl + '/v1/client-security/token/generate', {params: params, withCredentials: true})
      .pipe(map((response: TokenResponse) => response));
  }

  resendActivationCode(email) {
    let params = new HttpParams({encoder: new WebHttpUrlEncodingCodec() }).set('email', email);

    return this.http.get(environment.apiUrl + '/v1/client-security/token/activation/generate', {params: params})
      .pipe(map((response: any) => response));
  }
}


export class WebHttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return encodeURIComponent(k); }

  encodeValue(v: string): string { return encodeURIComponent(v); }

  decodeKey(k: string): string { return decodeURIComponent(k); }

  decodeValue(v: string) { return decodeURIComponent(v); }
}
