import {LayoutAction, LayoutActionTypes} from './layout.actions';
import * as layActions from './layout.actions';

export const LAYOUT_FEATURE_KEY = 'layout';

/**
 * Interface for the 'Layout' data used in
 *  - LayoutState, and
 *  - layoutReducer
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {
}

export interface LayoutState {
  isSidebarExpanded: boolean;
  isMobileView: boolean;
  isFreeCoins: boolean;
  collapsedMenu: string;
  windowHeight: number;
  windowWidth: number;
  breakpoint: string;
  bodyClass: string;
  themeClass: string;
  hiddenNavBar: boolean;
  showLoader: boolean;
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: LayoutState;
}

export const initialState: LayoutState = {
  isSidebarExpanded: false,
  isFreeCoins:  checkGameCurrency(),
  isMobileView: false,
  collapsedMenu: null,
  windowHeight: 0,
  windowWidth: 0,
  breakpoint: 'lg',
  bodyClass: '',
  hiddenNavBar: false,
  themeClass: checkTheme(),
  showLoader: false
};

function checkGameCurrency() {
  if (!localStorage.getItem('gameCurrency')) {
    localStorage.setItem('gameCurrency', 'FUN_COINS');
  }
  return localStorage.getItem('gameCurrency') !== 'QUIZ_COINS';
}

function breakpoints(width) {

  if (width < 599) {
    return 'xs';
  }

  if (width > 599 && width < 767) {
    return 'sm';
  }

  if (width > 767 && width < 1030) {
    return 'sl';
  }

  if (width > 1029 && width < 1199) {
    return 'md';
  }

  if (width > 1198 && width < 1366) {
    return 'mt';
  }

  if (width > 1200 && width < 1439) {
    return 'lg';
  }

  return 'xl';
}

export function layoutReducer(
  state: LayoutState = initialState,
  action: LayoutAction
): LayoutState {
  switch (action.type) {

    case LayoutActionTypes.HideNavigation: {
      state = {
        ...state,
        hiddenNavBar: true
      };
      break;
    }

    case LayoutActionTypes.ShowNavigation: {
      state = {
        ...state,
        hiddenNavBar: false
      };
      break;
    }

    case LayoutActionTypes.ToggleNavigation: {
      state = {
        ...state,
        hiddenNavBar: !state.hiddenNavBar
      };
      break;
    }

    case LayoutActionTypes.CollapseSidebar: {
      state = {
        ...state,
        collapsedMenu: action.payload
      };
      break;
    }

    case LayoutActionTypes.SetBodyClass: {
      state = {
        ...state,
        bodyClass: action.payload
      };
      break;
    }

    case LayoutActionTypes.ToggleSideBarMenu: {
      state = {
        ...state,
        isSidebarExpanded: !state.isSidebarExpanded
      };
      break;
    }

    case LayoutActionTypes.ShowLoader: {
      state = {
        ...state,
        showLoader: true
      };
      break;
    }

    case LayoutActionTypes.HideLoader: {
      state = {
        ...state,
        showLoader: false
      };
      break;
    }

    case LayoutActionTypes.ToggleIsFreeCoins: {

      state = {
        ...state,
        isFreeCoins: !state.isFreeCoins
      };
      localStorage.setItem('gameCurrency', state.isFreeCoins ? 'FUN_COINS' : 'QUIZ_COINS');
      break;
    }

    case LayoutActionTypes.CloseSideBarMenu: {
      state = {
        ...state,
        isSidebarExpanded: false
      };
      break;
    }

    case LayoutActionTypes.OpenSideBarMenu: {
      state = {
        ...state,
        isSidebarExpanded: true
      };
      break;
    }


    case LayoutActionTypes.ResizeWindow: {
      const height: number = action.payload.height;
      const width: number = action.payload.width;
      const isMobile: boolean = width < 768;
      const breakPoint: string = breakpoints(width);
      state = {
        ...state,
        windowHeight: height,
        windowWidth: width,
        isMobileView: isMobile,
        breakpoint: breakPoint
      };
      break;
    }

    case LayoutActionTypes.ChangeTheme: {

      const theme: string = action.payload.theme;

      localStorage.setItem('theme', action.payload.theme);


      document.querySelector('body').className = theme;

      state = {
        ...state,
        themeClass: theme
      };
      break;
    }

  }
  return state;
}


function checkTheme(): string {
  const theme: string = localStorage.getItem('theme');

  if (theme) {
    document.querySelector('body').className = theme;
    return theme;
  } else {
    return 'qnm-light-theme';
  }
}
