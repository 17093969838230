import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRegisterStore from './index';
import * as fromUserStore from '@qnm/+state/user';
import { UserService } from '@qnm/services/user/user.service';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable()
export class RegisterCompleteEffects {
  constructor(private actions$: Actions,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private localize: LocalizeRouterService) {
  }

  registerComplete$ = createEffect(() => this.actions$.pipe(
    ofType(fromRegisterStore.registerCompleteRequest),
    exhaustMap((data: any) => {
      return this.userService.completeRegister(data.registration, data.token.token)
        .pipe(
          map(res => fromRegisterStore.registerCompleteRequestSuccess({user: res})),
          catchError(err => of(fromRegisterStore.registerCompleteRequestFailure({error: err})))
        );
    })
    )
  );

  registerCompleteSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromRegisterStore.registerCompleteRequestSuccess),
        map((action) => fromUserStore.loadUserSuccess({user: action.user})),
        tap(data => {
          this.router.navigate([this.localize.translateRoute('/')]);
        })
      ),
    // {dispatch: false}
  );
}
