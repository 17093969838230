import { Action, createReducer, on } from '@ngrx/store';
import * as stageActions from './stage.actions';

export interface StageState {
  stageNumber: number;
  countdownLength: number;
  roundType?: string;
}

export const initialStageState: StageState = {
  stageNumber: 0,
  countdownLength: 0,
  roundType: null
};


const _stageReducer = createReducer(initialStageState,
  on(stageActions.startStageCountdown, (state, payload) => ({...state, ...payload.payload})),
  on(stageActions.resetStageState, () => ({...initialStageState})),
);

export function stageReducer(state: StageState | undefined, action: Action) {
  return _stageReducer(state, action);
}
