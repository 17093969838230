import { LobbyResponse } from '@qnm/interfaces/lobby/lobby.interface';
import * as fromLobbyActions from './lobby.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface LobbyState extends LobbyResponse{
  error?: any;
  isLoading: boolean;
  isLoaded: boolean;
  category: any;
  categoryParams: any;
}

export const initialState: LobbyState = {
  isLoaded: false,
  isLoading: false,
  currentLobby: localStorage.getItem('currentLobby') && localStorage.getItem('currentLobby') !== 'DEMO_INSTANT_GAME' ? localStorage.getItem('currentLobby') : 'INSTANT',
  lobbies: null,
  category: null,
  categoryParams: null
};

const _lobbyReducer = createReducer(
  initialState,
  on(fromLobbyActions.getLobbiesRequest, state => ({...state, isLoading: true, isLoaded: false, error: null})),
  on(fromLobbyActions.getLobbiesRequestSuccess, (state, {lobbies}) => ({...state, isLoading: false, isLoaded: true, ...lobbies, error: null})),
  on(fromLobbyActions.getLobbiesRequestFailure, (state, {error}) => ({...state, isLoading: false, isLoaded: true, error})),
  on(fromLobbyActions.joinToLobbyRequest, (state, {lobby}) => ({...state, currentLobby: lobby})),
  on(fromLobbyActions.getLobbyCategoriesParamsSuccess, (state, payload) => ({...state, categoryParams: payload.payload.data})),
  on(fromLobbyActions.getLobbyCategoriesSuccess, (state, payload) => ({...state, category: payload.payload.categories})),
  on(fromLobbyActions.WsUpdateLobby, (state, {lobby}) => updateLobby(state, lobby)),
);

export function lobbyReducer(state: LobbyState | undefined, action: Action) {
  return _lobbyReducer(state, action);
}

function updateLobby(state, lobby) {
  let lobbies = [];
  if (state.lobbies) {
    lobbies = state.lobbies.map( obj => {
      if (obj.id === lobby.id) {
        obj = lobby;
      }
      return obj;
    });
  } else {
    lobbies[lobby.id] = lobby;
  }


  return {...state, lobbies};
}
