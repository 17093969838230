import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  leaveTournament(id: any) {
    return this.http.post(environment.apiUrl + '/v1/tournament/resign', {tournamentId: id}, {withCredentials: true}).pipe(
      map((response) => response));
  }

  leaveGame(gameId: string) {
    return this.http.post(environment.apiUrl + `/v1/instant-game/resign/${gameId}`, {}, {withCredentials: true}).pipe(
      map((response) => response));
  }

  getTournamentLadder(id: string) {
    return this.http.get(environment.apiUrl + `/v1/tournament/draw/${id}`, {withCredentials: true}).pipe(
      map((response) => response));
  }

  getRestoreTournament(id: string) {
    return this.http.get(environment.apiUrl + `/v1/tournament/restore/${id}`, {withCredentials: true}).pipe(
      map((response) => response));
  }
}
