import { Action, createReducer, on } from '@ngrx/store';
import * as webSocketActions from './web-socket.actions';

export interface WebSocketState {
  connected: boolean;
  loading: boolean;
  closed: boolean;
}

export const initialState: WebSocketState = {
  connected: false,
  loading: false,
  closed: false
};

const _webSocketReducer = createReducer(
  initialState,
  on(webSocketActions.WSconnect, state => ({...state, loading: true, closed: false})),
  on(webSocketActions.WSconnectSuccess, state => ({...state, loading: false, closed: false, connected: true})),
  on(webSocketActions.WSconnectFailure, state => ({...state, loading: false, closed: true, connected: false})),
  on(webSocketActions.WScloseSuccess, state => ({...state, loading: false, closed: true, connected: false})),
);

export function webSocketReducer(state: WebSocketState | undefined, action: Action) {
  return _webSocketReducer(state, action);
}
