import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'qnm-change-locale-info-modal',
  templateUrl: './change-locale-info-modal.component.html',
  styleUrls: ['./change-locale-info-modal.component.scss']
})
export class ChangeLocaleInfoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangeLocaleInfoModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
