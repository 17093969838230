<section class="popup">
  <div class="popup__box" >
    <h1 class="popup__title">{{'change_locale.popup.header' | translate}}</h1>
    <a class="popup__close" (click)="onNoClick()">
      <img src="/assets/images/i-shape-close.svg" alt="Close popup">
    </a>
    
    <div class="popup__body">
      <p>
        {{'change_locale.popup.description' | translate}}
      </p>
    </div>
    <div class="popup__btn-wrap">
      <div class="form-button form-button--profile form-button--cancel">
        <button class="form-button__btn" type="button" (click)="onNoClick()">
          <span>{{'change_locale.popup.cancel' | translate}}</span>
        </button>
      </div>
      <div class="form-button form-button--profile" >
        <button class="form-button__btn"  type="button" (click)="onNoClick()" [routerLink]="['/settings/profile-details' | localize]">
          <span>{{'change_locale.popup.change' | translate}}</span>
        </button>
      </div>
    </div>
  
  </div>
</section>
