import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserService} from '@qnm/services/user/user.service';
import * as userActions from '@qnm/+state/user/user.actions';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {User} from '@qnm/interfaces/user.interface';
import {of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {NotificationService} from 'qnm-shared/modules/notifications/services/notification/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeLocaleInfoModalComponent
} from 'qnm-shared/components/change-locale-info-modal/change-locale-info-modal.component';
import {KycInfoModalComponent} from 'qnm-shared/components/kyc-info-modal/kyc-info-modal.component';
import {UserFacade} from '@qnm/+state/user/user.facade';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private userFacade: UserFacade,
    private notificationService: NotificationService,
    private localize: LocalizeRouterService,
  ) {
  }

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUser),
      exhaustMap(() => this.userService.getUser()
        .pipe(
          map((user: User) => userActions.loadUserSuccess({user})),
          tap(() => {
            this.userFacade.getNickNameParameters();
          }),
          catchError(err => of(userActions.loadUserFailure({error: err})))
        )
      )
    )
  );

  loadUserSuccess$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(userActions.loadUserSuccess),
        tap(data => {
          const urlLang = this.translateService.currentLang;
          const usrLocale = data.user['locale'];
          const kycStatusNotAllowed = ['requested'];

          if (urlLang !== usrLocale && kycStatusNotAllowed.indexOf(data.user.kycStatus) === -1) {
            this.localize.changeLanguage(usrLocale);

            // condition added that the user logged in by the provider does not see this modal
            if (!data.user.agreement1 === null) {
              this.changeLocaleInfo();
            }
          }

          if (kycStatusNotAllowed.indexOf(data.user.kycStatus) > -1) {
            if (this.router.url.indexOf('profile-details') === -1) {
              const kycInProgressModalShowed = localStorage.getItem('kycInProgressModalShowed');

              if (!kycInProgressModalShowed) {
                localStorage.setItem('kycInProgressModalShowed', 'true');

                this.kycInfo(data.user.kycStatus);
              }
            }
          }

          if (data.user.showManual) {
            this.router.navigate(
              [this.localize.translateRoute('/')],
              {queryParams: {showManual: true}}
            );
          }
        })
      );
    },
    {dispatch: false}
  );

  getNickNameParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getNickNameParameters),
      exhaustMap(action => this.userService.getUserNickNameParams()
        .pipe(
          map((payload) => userActions.getNickNameParametersSuccess({payload})),
          catchError(err => of(userActions.getNickNameParametersFailure({error: err})))
        )
      )
    )
  );

  updateUserNickName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserNickName),
      exhaustMap(action => this.userService.updateUserNickName(action.payload)
        .pipe(
          map((user) => userActions.updateUserNickNameSuccess({payload: user})),
          catchError(err => of(userActions.updateUserNickNameFailure({error: err})))
        )
      )
    )
  );

  updateUserNickNameSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserNickNameSuccess),
      map(() => {
        this.notificationService.notify({
          type: 'success',
          message: 'profile.user.update_nickname.success'
        });
        this.dialog.closeAll();

        this.userFacade.getNickNameParameters();

        return userActions.loadUser();
      })
    )
  );

  updateUserNickNameFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserNickNameFailure),
      map(() => {
        this.notificationService.notify({
          type: 'danger',
          message: 'profile.user.update_nickname.failure'
        });
        return userActions.loadUser();
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserData),
      exhaustMap(action => this.userService.updateUserData(action.payload)
        .pipe(
          map((user) => userActions.updateUserDataSuccess({payload: user})),
          catchError(err => of(userActions.updateUserDataFailure({error: err})))
        )
      )
    )
  );

  updateUserPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserPersonalData),
      exhaustMap(action => this.userService.updateUserPersonalData(action.payload)
        .pipe(
          map(() => userActions.loadUser()),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(userActions.updateUserPersonalDataFailure({error: err})))
        )
      )
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.userLogout),
      exhaustMap(() => this.userService.userLogout()
        .pipe(
          map(() => userActions.userLogoutSuccess()),
          catchError(() => of(userActions.userLogoutFailure()))
        )
      )
    )
  );

  logoutUserSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(userActions.userLogoutSuccess),
        tap(data => {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('kycInProgressModalShowed');

          this.router.navigate([this.localize.translateRoute('/auth')]);
        })
      ),
    {dispatch: false}
  );

  userLogoutFailure$ = createEffect(() =>
      this.actions$.pipe(
        ofType(userActions.userLogoutFailure),
        tap(() => {
          this.notificationService.notify({
            type: 'danger',
            message: 'user.logout.failure'
          });
        })
      ),
    {dispatch: false}
  );

  uploadUserAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadUserAvatar),
      exhaustMap(data => this.userService.uploadUserAvatar(data.payload)
        .pipe(
          map(avatar => userActions.uploadUserAvatarSuccess({payload: avatar})),
          catchError(err => of(userActions.uploadUserAvatarFailure({error: err})))
        )
      )
    )
  );

  uploadUserAvatarSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadUserAvatarSuccess),
      map(() => {
        this.notificationService.notify({
          type: 'success',
          message: 'profile.user.avatar.success'
        });
        return userActions.loadUser();
      })
    )
  );

  uploadUserAvatarFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadUserAvatarFailure),
      tap((error) => {
        this.notificationService.notify({
          type: 'warning',
          message: error.error.error.violations[0].label,
          parameters: error.error.error.violations[0].parameters
        });
      })
    ), {dispatch: false}
  );

  deleteUserAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUserAvatar),
      exhaustMap(() => this.userService.deleteUserAvatar()
        .pipe(
          map(() => userActions.deleteUserAvatarSuccess()),
          catchError(err => of(userActions.deleteUserAvatarFailure({error: err})))
        )
      )
    )
  );

  deleteUserAvatarSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUserAvatarSuccess),
      map(() => {
        return userActions.loadUser();
      })
    )
  );

  updateUserDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserDataSuccess),
      map(() => {
        return userActions.loadUser();
      })
    )
  );

  changeUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.changeUserPassword),
      exhaustMap(action => this.userService.changeUserPassword(action.payload)
        .pipe(
          map(() => userActions.changeUserPasswordSuccess()),
          catchError(err => of(userActions.changeUserPasswordFailure({error: err})))
        )
      )
    )
  );

  changeUserPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.changeUserPasswordSuccess),
      tap(() => {
        this.dialog.closeAll();
        return this.notificationService.notify({
          type: 'success',
          message: 'profile.user.change_password.success'
        });
      })
    ), {dispatch: false}
  );

  changeUserAgreementss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserAgreements),
      exhaustMap(action => this.userService.changeUserAgreements(action.payload)
        .pipe(
          map((user) => userActions.updateUserAgreementsSuccess({payload: action.payload})),
          catchError(err => of(userActions.updateUserAgreementsFailure({error: err})))
        )
      )
    )
  );

  changeUserAgreementsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserAgreementsSuccess),
      tap(() => {
        if (!this.router.url.includes('register-by-provider-agreements')) {
          return this.notificationService.notify({
            type: 'success',
            message: 'profile.user.agreements.success'
          });
        }
      })
    ), {dispatch: false}
  );

  changeUserLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserLocale),
      exhaustMap(action => this.userService.changeUserAgreements(action.payload)
        .pipe(
          map((user) => userActions.updateUserLocaleSuccess({payload: action.payload})),
          catchError(err => of(userActions.updateUserLocaleFailure({error: err})))
        )
      )
    )
  );

  changeUserLocaleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserLocaleSuccess),
      tap((data) => {
        this.dialog.closeAll();
        this.localize.changeLanguage(data.payload.locale);
        return this.notificationService.notify({
          type: 'success',
          message: 'change_additional_data.success'
        });
      })
    ), {dispatch: false}
  );

  getUserBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserBankAccounts),
      exhaustMap(action => this.userService.getUserBankAccounts()
        .pipe(
          map((payload) => userActions.getUserBankAccountsSuccess({payload})),
          catchError(err => of(userActions.getUserBankAccountsFailure({error: err})))
        )
      )
    )
  );

  getVerifiedUserBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getVerifiedUserBankAccounts),
      exhaustMap(action => this.userService.getVerifiedUserBankAccounts()
        .pipe(
          map((payload) => userActions.getVerifiedUserBankAccountsSuccess({payload})),
          catchError(err => of(userActions.getUserBankAccountsFailure({error: err})))
        )
      )
    )
  );

  getUnreadMessagesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUnreadMessagesCount),
      exhaustMap(() => this.userService.getUnreadMessagesCount()
        .pipe(
          map((payload: any) => userActions.getUnreadMessagesCountSuccess({payload})),
          catchError(err => of(userActions.getUnreadMessagesCountFailure({error: err})))
        )
      )
    )
  );



  disableManual$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.disableShowManual),
      exhaustMap(() => this.userService.disableManual()
        .pipe(
          map(() => userActions.disableShowManualSuccess()),
          catchError(err => of(userActions.disableShowManualFailure()))
        )
      )
    )
  );

  disableManualSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.disableShowManualSuccess),
      tap(() => {
        //this.router.navigate([this.localize.translateRoute('/')]);
      })
    ), {dispatch: false}
  );

  changeLocaleInfo(): void {
    const dialogRef = this.dialog.open(ChangeLocaleInfoModalComponent);
  }

  kycInfo(kycStatus: string): void {
    const dialogRef = this.dialog.open(KycInfoModalComponent, {data: {kycStatus}, maxWidth: '100%'});
  }
}
