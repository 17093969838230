import { Action, createReducer, on } from '@ngrx/store';
import * as audioActions from './audio.actions';

export interface AudioState {
  isEnable: boolean;
}

export const initialAudioState: AudioState = {
  isEnable: true
};

const _audioReducer = createReducer(initialAudioState,
    on(audioActions.setAudio, (state, { isEnable }) => {
      localStorage.setItem('audioEnabled', isEnable.toString());
      return {...state, isEnable};
    })
  );

export function audioReducer(state: AudioState | undefined, action: Action) {
  return _audioReducer(state, action);
}
