import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '@qnm/interfaces/user.interface';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {RegisterCompleteRequest, RegisterResponse} from 'qnm-auth/interfaces/register.interface';
import {UploadKycDocument} from 'qnm-shared/kyc.interface';
import {BankAccountDTO} from '@qnm/interfaces/bank-account/bank-account.interface';
import {NickName, NickNameParameters} from 'qnm-auth/interfaces/nick-name.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private router: Router,
              private localize: LocalizeRouterService) {
  }

  userLogout(): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/client-security/user/logout', {}, {withCredentials: true}).pipe(map((response: any) => response));
  }

  getUserMessages(payload): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/inbox/?filters[firstMessageDate][0][value]=${payload.start}&filters[firstMessageDate][0][operator]=gte&filters[firstMessageDate][1][value]=${payload.end}&filters[firstMessageDate][1][operator]=lte&sort[]=-lastMessageDate&limit=${payload.limit}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getThreadDetails(payload): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/inbox/thread/${payload.id}?sort[]=sentAt`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  setThreadAsRead(payload): Observable<any> {
    return this.http.post(environment.apiUrl + `/v1/inbox/set-as-read/thread/${payload.id}`, {}, {withCredentials: true}).pipe(
      map((response) => response));
  }

  sendMessage(payload): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/inbox/compose', payload, {withCredentials: true}).pipe(
      map((response) => response));
  }

  disableManual(): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/client-security/user/disable-show-manual', {}, {withCredentials: true}).pipe(
      map((response) => response));
  }

  getUnreadMessagesCount(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/inbox/count-unread', {withCredentials: true}).pipe();
  }

  getUser(): Observable<User> {
    return this.http.get(environment.apiUrl + '/v1/client-security/user', {withCredentials: true}).pipe(
      map((response: User) => response));
  }

  getUserKyc(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/client-security/user/kyc', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserLoginHistory(payload): Observable<any> {
    const offset = payload.limit - 10;
    return this.http.get(environment.apiUrl + `/v1/client-security/user/history?offset=${offset}&limit=10`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserPaymentHistory(payload): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/transaction/?filters[walletCurrency][0][value]=${payload.id}&filters[walletCurrency][0][operator]=in&filters[createdAt][0][value]=${payload.start}&filters[createdAt][0][operator]=gte&filters[createdAt][1][value]=${payload.end}&filters[createdAt][1][operator]=lte&limit=${payload.limit}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserWalletsHistory(payload): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/wallet-history?filters[walletCurrency][0][value]=${payload.id}&filters[walletCurrency][0][operator]=eq&filters[operationCreatedAt][0][value]=${payload.start}&filters[operationCreatedAt][0][operator]=gte&filters[operationCreatedAt][1][value]=${payload.end}&filters[operationCreatedAt][1][operator]=lte&limit=${payload.limit}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserGameStatistics(payload): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/player-stats/currency/${payload}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserGameHistory(payload): Observable<any> {
    const offset = payload.limit - 10;
    return this.http.get(environment.apiUrl + `/v1/game-history-item?filters[startedAt][0][value]=${payload.start}&filters[startedAt][0][operator]=gte&filters[endedAt][1][value]=${payload.end}&filters[endedAt][1][operator]=lte&filters[currency][2][operator]=eq&filters[currency][2][value]=${payload.currency}&sort[]=${encodeURIComponent('-startedAt')}&limit=10&offset=${offset}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserTournamentHistory(payload): Observable<any> {
    const offset = payload.limit - 10;
    return this.http.get(environment.apiUrl + `/v1/tournament/list?filters[startedAt][0][value]=${payload.start}&filters[startedAt][0][operator]=gte&filters[startedAt][1][value]=${payload.end}&filters[startedAt][1][operator]=lte&filters[currency][2][value]=${payload.currency}&sort[]=${encodeURIComponent('-startedAt')}&limit=10&offset=${offset}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserGameDetails(payload): Observable<any> {
    const offset = payload.limit - 10;
    return this.http.get(environment.apiUrl + `/v1/game-history-item-details/${payload.gameId}`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  completeRegister(register: RegisterCompleteRequest, confirmToken: any) {
    let headers = new HttpHeaders();
    headers = headers.set('confirmation-token-value', confirmToken);
    headers = headers.append('confirmation-token-type', 'phone_number_confirmation');
    return this.http.put(environment.apiUrl + '/v1/client-security/user/complete', register, {
      headers,
      withCredentials: true
    }).pipe(
      map((response: RegisterResponse) => response));
  }

  completeRegisterPreValidation(register: RegisterCompleteRequest) {
    return this.http.put(environment.apiUrl + '/v1/client-security/user/validate', register, {
      withCredentials: true
    }).pipe(
      map((response: any) => response));
  }

  updateUserData(user) {
    return this.http.put(environment.apiUrl + '/v1/client-security/user', user, {
      withCredentials: true
    }).pipe(
      map((response: any) => response));
  }

  updateUserPersonalData(user) {
    return this.http.post(environment.apiUrl + '/v1/client-security/user/change-personal-data', user, {
      withCredentials: true
    }).pipe(
      map((response: any) => response));
  }

  changeUserPassword(payload) {
    return this.http.put(environment.apiUrl + '/v1/client-security/user/password', payload, {
      withCredentials: true
    }).pipe(
      map((response: any) => response));
  }

  changeUserAgreements(payload) {
    return this.http.patch(environment.apiUrl + '/v1/client-security/user', payload, {
      withCredentials: true
    }).pipe(
      map((response: any) => response));
  }

  getUserAvatar() {
    return this.http.get(environment.apiUrl + '/v1/client-security/user/avatar', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  uploadUserKycDocument(payload: UploadKycDocument) {
    const formData = new FormData();
    formData.append('file', payload.file, payload.file.fileName);

    return this.http.post(environment.apiUrl + `/v1/client-security/user/kyc/document/${payload.type}`,
      formData, {withCredentials: true})
      .pipe(
        map((response: any) => response)
      );
  }

  uploadUserAvatar(avatar) {
    const formData = new FormData();
    formData.append('avatar', avatar, avatar.fileName);

    return this.http.post(environment.apiUrl + '/v1/client-security/user/avatar',
      formData, {withCredentials: true})
      .pipe(
        map((response: any) => response)
      );
  }

  deleteUserAvatar() {
    return this.http.delete(environment.apiUrl + '/v1/client-security/user/avatar', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getUserBankAccounts(): Observable<BankAccountDTO[]> {
    return this.http.get(environment.apiUrl + `/v1/client-security/user/bank-account`, {withCredentials: true}).pipe(
      map((response: BankAccountDTO[]) => response));
  }

  getVerifiedUserBankAccounts(): Observable<BankAccountDTO[]> {
    return this.http.get(environment.apiUrl + `/v1/client-security/user/bank-account/verified`, {withCredentials: true}).pipe(
      map((response: BankAccountDTO[]) => response));
  }

  setUserBankAccount(payload): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/client-security/user/bank-account', payload, {withCredentials: true}).pipe(
      map((response) => response));
  }

  getUserNickNameParams(): Observable<NickNameParameters> {
    return this.http.get(environment.apiUrl + '/v1/client-security/user/nick', {withCredentials: true}).pipe(
      map((response: NickNameParameters) => response));
  }

  updateUserNickName(nickName: string): Observable<any> {
    const payload = {
      nickName
    };

    return this.http.post(environment.apiUrl + '/v1/client-security/user/nick', payload, {withCredentials: true}).pipe(
      map((response) => response));
  }
}
