import {Action, createReducer, on} from '@ngrx/store';
import * as walletActions from './wallet.actions';

export interface WalletState {
  wallets: any;
  bonuses: any;
  error?: any;
}

export const initialWalletState: WalletState = {
  wallets: null,
  bonuses: null
};

const _walletReducer = createReducer(
  initialWalletState,
  on(walletActions.getBonuses, state => ({...state, error: null})),
  on(walletActions.getBonusesSuccess, (state, payload) => ({...state, bonuses: payload.bonuses, error: null})),
  on(walletActions.getBonusesFailure, (state, payload) => ({...state, error: payload.error})),

  on(walletActions.getWallets, state => ({...state, error: null})),
  on(walletActions.getWalletsSuccess, (state, payload) => ({...state, wallets: payload.wallets, error: null})),
  on(walletActions.getWalletsFailure, (state, payload) => ({...state, error: payload.error})),

  on(walletActions.wsUpdateWallet, (state, payload: any) => {
    if (state.wallets && state.wallets.length) {
      const wallet = state.wallets.find(w => w.currency !== payload.currency);
      const {type, ...rest} = payload;
      rest.balance = payload.totalBalance;
      return {...state, wallets: [wallet, rest]};
    } else {
      return state;
    }
  }),
);

export function walletReducer(state: WalletState | undefined, action: Action) {
  return _walletReducer(state, action);
}
