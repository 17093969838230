import { createAction, props } from '@ngrx/store';
import { User } from '@qnm/interfaces/user.interface';
import {BankAccountDTO} from '@qnm/interfaces/bank-account/bank-account.interface';
import {NickNameParameters} from 'qnm-auth/interfaces/nick-name.interface';

export const loadUser = createAction('[User] Load Request');
export const loadUserSuccess = createAction('[User] Load Success', props<{ user: User}>());
export const loadUserFailure = createAction('[User] Load Failure', props<{ error: any}>());

export const userLogout = createAction('[Logout] Logout request');
export const userLogoutSuccess = createAction('[Logout] Logout success');
export const userLogoutFailure = createAction('[Logout] Logout failure');


export const uploadUserAvatar = createAction('[Avatar] Upload avatar', props<{payload: any}>());
export const uploadUserAvatarSuccess = createAction('[Avatar] Upload avatar success', props<{payload: any}>());
export const uploadUserAvatarFailure = createAction('[Avatar] Upload avatar failure', props<{error: any}>());

export const deleteUserAvatar = createAction('[Avatar] Delete avatar');
export const deleteUserAvatarSuccess = createAction('[Avatar] Delete avatar success');
export const deleteUserAvatarFailure = createAction('[Avatar] Delete avatar failure', props<{error: any}>());

export const updateUserData = createAction('[User Update] Update user data', props<{payload: any}>());
export const updateUserDataSuccess = createAction('[User Update] Update user data success', props<{payload: any}>());
export const updateUserDataFailure = createAction('[User Update] Update user data failure', props<{error: any}>());

export const updateUserPersonalData = createAction('[User Personal Data Update] Update user personal data', props<{payload: any}>());
export const updateUserPersonalDataSuccess = createAction('[User Personal Data Update] Update user personal data success', props<{payload: any}>());
export const updateUserPersonalDataFailure = createAction('[User Personal Data Update] Update user personal data failure', props<{error: any}>());

export const changeUserPassword = createAction('[Change Password] Change password', props<{payload: any}>());
export const changeUserPasswordSuccess = createAction('[Change Password] Change password success');
export const changeUserPasswordFailure = createAction('[Change Password] Change password failure', props<{error: any}>());

export const updateUserAgreements = createAction('[Change Agreements] Change agreements', props<{payload: any}>());
export const updateUserAgreementsSuccess = createAction('[Change Agreements] Change agreements success', props<{payload: any}>());
export const updateUserAgreementsFailure = createAction('[Change Agreements] Change agreements failure', props<{error: any}>());

export const updateUserLocale = createAction('[Change Locale] Change Locale', props<{payload: any}>());
export const updateUserLocaleSuccess = createAction('[Change Locale] Change Locale success', props<{payload: any}>());
export const updateUserLocaleFailure = createAction('[Change Locale] Change Locale failure', props<{error: any}>());

export const getUserBankAccounts = createAction('[Bank Account] Get bank accounts');
export const getUserBankAccountsSuccess = createAction('[Bank Account] Get bank accounts success', props<{payload: BankAccountDTO[]}>());
export const getUserBankAccountsFailure = createAction('[Bank Account] Get bank accounts failure', props<{error: any}>());

export const getVerifiedUserBankAccounts = createAction('[Verified Bank Account] Get verified bank accounts');
export const getVerifiedUserBankAccountsSuccess = createAction('[Verified Bank Account] Get verified bank accounts success', props<{payload: BankAccountDTO[]}>());
export const getVerifiedUserBankAccountsFailure = createAction('[Verified Bank Account] Get verified bank accounts failure', props<{error: any}>());

export const getUnreadMessagesCount = createAction('[Unread Messages] Get unread messages count');
export const getUnreadMessagesCountSuccess = createAction('[Unread Messages] Get unread messages count success', props<{payload: any}>());
export const getUnreadMessagesCountFailure = createAction('[Unread Messages] Get unread messages count failure', props<{error: any}>());

export const getNickNameParameters = createAction('[Nick Name] Get nick name parameters');
export const getNickNameParametersSuccess = createAction('[Nick Name] Get nick name parameters success', props<{payload: NickNameParameters}>());
export const getNickNameParametersFailure = createAction('[Nick Name] Get nick name parameters failure', props<{error: any}>());

export const updateUserNickName = createAction('[Update User Nick Name] Update nick name parameters', props<{payload: string}>());
export const updateUserNickNameSuccess = createAction('[Update User Nick Name] Update nick name parameters success', props<{payload: string}>());
export const updateUserNickNameFailure = createAction('[Update User Nick Name] Update nick name parameters failure', props<{error: any}>());


export const disableShowManual = createAction('[Manual] disable request');
export const disableShowManualSuccess = createAction('[Manual] disable success');
export const disableShowManualFailure = createAction('[Manual] disable failure');
