import { createAction, props } from '@ngrx/store';

export const WSconnect = createAction('[WebSocket] WSConnect', props<{ payload?: boolean }>());
export const WSconnectSuccess = createAction('[WebSocket] WSConnect Success');
export const WSconnectFailure = createAction('[WebSocket] WSConnect Failure');


export const WSclose = createAction('[Websocket] WsClose');
export const WScloseSuccess = createAction('[Websocket] WsClose Success');
export const WScloseFailure = createAction('[Websocket] WsClose Failure');
