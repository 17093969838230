import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './containers/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from 'qnm-auth/interceptors/auth.interceptor';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule, FullRouterStateSerializer} from '@ngrx/router-store';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {reducers} from './+state/router.reducer';
import {JwtModule} from '@auth0/angular-jwt';
import {ErrorInterceptor} from 'qnm-shared/interceptors/error.interceptor';
import {UserFacade} from '@qnm/+state/user/user.facade';
import {DictionaryEffects} from './+state/dictionary/dictionary.effects';
import {DictionaryFacade} from './+state/dictionary/dictionary.facade';
import {UpdateLayoutDirective} from 'qnm-shared/directives/update-layout.directive';
import {LayoutFacade} from './+state/layout.facade';
import localeFr from '@angular/common/locales/fr';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {CookieService} from 'ngx-cookie-service';
registerLocaleData(localeFr);

import {AudioFacade} from '@qnm/+state/audio/audio.facade';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {NgxPayPalModule} from 'ngx-paypal';

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? []
  : [];

export function createTranslateLoader(http: HttpClient) {
  const date = new Date();
  const currentHours = date.getTime();

  return new TranslateHttpLoader(http, './assets/i18n/', '.json?h=' + currentHours);
}

export function tokenGetterFunc() {
  return localStorage.getItem('jwtToken');
}

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === '[Logout] Logout success') {
      state = {
        ...state,
        qnm: {
          lobby: {
            currentLobby: localStorage.getItem('currentLobby') && localStorage.getItem('currentLobby') !== 'DEMO_INSTANT_GAME' ? localStorage.getItem('currentLobby') : 'INSTANT'
          }
        }
      };
    }
    return reducer(state, action);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    UpdateLayoutDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFunc
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [clearState],
      runtimeChecks: {strictStateImmutability: false, strictActionImmutability: false}
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([DictionaryEffects]),
    StoreRouterConnectingModule.forRoot({serializer: FullRouterStateSerializer}),
    environment.gtmId ?  GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }) : [],
    NgxPayPalModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    CookieService,
    UserFacade,
    LayoutFacade,
    DictionaryFacade,
    AudioFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
