import {NgModule} from '@angular/core';
import {Location} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterService,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import {LoggedGuard} from 'qnm-auth/guards/logged.guard';
import {AuthGuard} from 'qnm-auth/guards/auth.guard';
import {UserGuard} from '@qnm/guards/user.guard';
import {environment} from '../environments/environment';
import {map, take} from 'rxjs/operators';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  const date = new Date();
  const currentHours = date.getTime();
  return new LocalizeRouterHttpLoader(translate, location, settings, http, './assets/i18n/config.json?h=' + currentHours);
}


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'page',
    loadChildren: () => import('./modules/cms/cms.module').then(m => m.CmsModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/qnm/qnm.module').then(m => m.QnmModule)
  },
  {path: '**', redirectTo: '/auth/login', pathMatch: 'full'}
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    LoggedGuard,
    UserGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(private localize: LocalizeRouterService, private http: HttpClient) {
    if (!localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE')
      && window.location.pathname.indexOf('/page/') === -1) {
      this.http.get(environment.apiUrl + '/v1/locale').pipe(
        take(1),
        map((response: any) => response)).subscribe(data => {
        this.localize.changeLanguage(data.code);
      });
    }
  }
}
