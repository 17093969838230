import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as tokenActions from '@qnm/+state/token/token.actions';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TokenService } from '@qnm/services/token/token.service';
import { TokenResponse } from '@qnm/interfaces/token/token.interface';
import { NotificationService } from 'qnm-shared/modules/notifications/services/notification/notification.service';

@Injectable()
export class TokenEffects {
  constructor(
    private actions$: Actions,
    private tokenService: TokenService,
    private notificationService: NotificationService,
  ) {
  }

  tokenGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenActions.generateToken),
      exhaustMap(action => this.tokenService.generateToken(action.phone)
        .pipe(
          map((token: TokenResponse) => tokenActions.generateTokenSuccess({payload: token})),
          catchError(err => of(tokenActions.generateTokenFailure({error: err})))
        )
      )
    )
  )

  tokenReGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenActions.regenerateToken),
      exhaustMap(action => this.tokenService.generateToken(action.phone)
        .pipe(
          map((token: TokenResponse) => tokenActions.regenerateTokenSuccess({payload: token})),
          catchError(err => of(tokenActions.regenerateTokenFailure({error: err})))
        )
      )
    )
  )

  tokenReGenerateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenActions.regenerateTokenSuccess),
      tap(() => {
          this.notificationService.notify({
            type: 'success',
            message: 'resend_sms_token.notify.message'
          });
        }
      )
    ), {dispatch: false}
  )
}
