import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { LobbyResponse } from '@qnm/interfaces/lobby/lobby.interface';

@Injectable({
  providedIn: 'root'
})
export class LobbyService {
  constructor(private http: HttpClient,
              private router: Router,
              private localize: LocalizeRouterService) {
  }

  getLobbies() {
    return this.http.get(environment.apiUrl + '/v1/lobby', {withCredentials: true})
      .pipe(map((response: LobbyResponse) => response));
  }

  joinToLobby(lobby: string) {
    return this.http.put(environment.apiUrl + `/v1/lobby/join/${lobby}`, {},{withCredentials: true})
      .pipe(map((response: LobbyResponse) => response));
  }
}
