import { Action, createReducer, on } from '@ngrx/store';
import * as CmsActions from './cms.actions';


export interface CmsState {
  data: any;
  error: any;
  loading: boolean;
}

export const initialCmsState: CmsState = {
  data: null,
  error: null,
  loading: false,
}

const _cmsReducer = createReducer(initialCmsState,
  on(CmsActions.getCmsPage, state => ({...state, loading: true, error: null})),
  on(CmsActions.getCmsPageFailure, (state, action) => ({...state, loading: false, error: action.error.error})),
  on(CmsActions.getCmsPageSuccess, (state, action) => ({
    ...state, loading: false,
    error: null, data: action.payload
  }))
);

export function cmsReducer(state: CmsState | undefined, action: Action) {
  return _cmsReducer(state, action)
}
