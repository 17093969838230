import {Injectable} from '@angular/core';

@Injectable()
export class AudioService {
  public audio: HTMLAudioElement;

  private audioFilesForAllModules: string[] = [
    '/assets/audio/get-ready.mp3',
    // '/assets/audio/21130931_countdown_by_luca_clerici_preview.mp3',
    // '/assets/audio/tic-tac-27828.mp3',
    // '/assets/audio/Countdown_02.mp3',
    // '/assets/audio/decidemp3-14575.mp3',
    // '/assets/audio/failure-drum-sound-effect-2-7184.mp3',
    // '/assets/audio/short-success-sound-glockenspiel-treasure-video-game-6346.mp3',
  ];

  public prefetchAudio = (): Promise<string[]> => Promise.all(this.audioFilesForAllModules.map((url: string): Promise<string> => {
    const audio: HTMLAudioElement = new Audio();
    audio.src = url;
    return new Promise((res) => audio.addEventListener('canplaythrough', () => res(url), false));
    // once file is loaded, the promise will be resolved
    // the file will be kept by the browser as cache
  }))

  public setAudio(src: string): void {
    this.audio = new Audio();
    this.audio.src = src;
  }

  public playAudio(): void {
    // setTimeout(() => {
      this.audio.play();
    // }, 200);
  }

  public playAudioInLoop(): void {
    this.audio.loop = true;
  }

  public pauseAudio(): void {
    if(this.audio) {
      this.audio.pause();
      this.audio = undefined;
    }
  }

  public setAudioVolume(value: boolean, volume: number): void {
    if (value) {
      this.audio.volume = volume;
    } else {
      this.audio.volume = 0;
    }
  }
}
