import * as webSockets from './web-socket';
import * as userStore from './user';
import * as tokenStore from './token';
import * as registerStore from './register-complete';
import * as lobbyStore from './lobby';
import * as waitingRoomsStore from './lobby-waiting-rooms';
import * as lobbyFilters from './lobby-filters';
import * as gameStore from './game';
import * as stageStore from './stage';
import * as roundStore from './round';
import * as walletStore from './wallet';
import * as audioStore from './audio';
import * as notificationStore from './notification';
import * as cmsStore from '../../cms/+state/cms.reducer';
import * as activityStreamStore from './activity-stream';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { WebSocketEffects } from '@qnm/+state/web-socket/web-socket.effects';
import { UserEffects } from '@qnm/+state/user/user.effects';
import { TokenEffects } from '@qnm/+state/token/token.effects';
import { RegisterCompleteEffects } from '@qnm/+state/register-complete/register-complete.effects';
import { LobbyEffects } from '@qnm/+state/lobby/lobby.effects';
import { LobbyWaitingRoomsEffects } from '@qnm/+state/lobby-waiting-rooms/lobby-waiting-rooms.effects';
import { WalletEffects } from '@qnm/+state/wallet/wallet.effects';
import {GameEffects} from '@qnm/+state/game/game.effects';
import { NotificationEffects } from '@qnm/+state/notification/notification.effects';
import {ActivityStreamEffects} from '@qnm/+state/activity-stream/activity-stream.effects';

export interface QnMState {
  webSocket: webSockets.WebSocketState;
  user: userStore.UserState;
  token: tokenStore.TokenState;
  registerComplete: registerStore.RegisterCompleteState;
  lobby: lobbyStore.LobbyState;
  lobbyFilters: lobbyFilters.LobbyFiltersState;
  waitingRooms: waitingRoomsStore.LobbyWaitingRoomsState;
  game: gameStore.GameState;
  stage: stageStore.StageState;
  round: roundStore.RoundState;
  wallets: walletStore.WalletState;
  cms: cmsStore.CmsState;
  audio: audioStore.AudioState;
  notification: notificationStore.NotificationState;
  activityStream: activityStreamStore.ActivityStreamState;
}

export const reducers: ActionReducerMap<QnMState> = {
  webSocket: webSockets.webSocketReducer,
  user: userStore.userReducer,
  token: tokenStore.tokenReducer,
  registerComplete: registerStore.registerCompleteReducer,
  lobby: lobbyStore.lobbyReducer,
  lobbyFilters: lobbyFilters.lobbyFiltersReducer,
  waitingRooms: waitingRoomsStore.lobbyWaitingRoomsReducer,
  game: gameStore.gameReducer,
  stage: stageStore.stageReducer,
  round: roundStore.roundReducer,
  wallets: walletStore.walletReducer,
  cms: cmsStore.cmsReducer,
  audio: audioStore.audioReducer,
  notification: notificationStore.notificationReducer,
  activityStream: activityStreamStore.activityStreamReducer
};

export const getQnMState = createFeatureSelector<QnMState>(
  'qnm'
);

export const effects: any[] = [
  WebSocketEffects,
  WalletEffects,
  UserEffects,
  TokenEffects,
  RegisterCompleteEffects,
  LobbyEffects,
  LobbyWaitingRoomsEffects,
  GameEffects,
  NotificationEffects,
  ActivityStreamEffects

];
