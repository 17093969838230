import {Action, createReducer, on} from '@ngrx/store';
import * as notificationActions from './notification.actions';
import {Notification} from '@qnm/interfaces/notification/notification.interface';

export interface NotificationState {
  notifications: Notification[];
  error: any;
}

export const initialNotificationState: NotificationState = {
  notifications: [],
  error: null
};

const _notificationReducer = createReducer(initialNotificationState,
  on(notificationActions.getNotificationsRequest, (state) => ({...state, error: null})),
  on(notificationActions.getNotificationsSuccess, (state, {notifications}) => ({...state, notifications, error: null})),
  on(notificationActions.getNotificationsFailure, (state, {error}) => ({...state, error})),
  on(notificationActions.approveNotificationSuccess, (state, {data}) => ({...state, notifications: state.notifications.filter(item => item.id !== data.notificationId), error: null})),
  on(notificationActions.approveNotificationFailure, (state, {error}) => ({...state, error}))
);

export function notificationReducer(state: NotificationState | undefined, action: Action) {
  return _notificationReducer(state, action);
}
