import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromLayout from './layout.reducer';
import * as fromDictionary from './dictionary/dictionary.reducer';

export interface State {
  layout: any;
  dictionary: fromDictionary.DictionaryState
}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.layoutReducer,
  dictionary: fromDictionary.dictionaryReducer
};
