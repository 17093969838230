import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private router: Router,
              private authService: AuthService,
              private localize: LocalizeRouterService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      return of(true);
    } else {
      this.router.navigate(
        [this.localize.translateRoute('/auth/login')],
        {state: {redirect: state.url}, queryParams: {...route.queryParams}});
      return of(false);
    }

  }
}
