import { Injectable } from '@angular/core';
import { NotificationOptions } from '../../interfaces/notification-options.interface'
import { NotificationQueueService } from '../notification-queue/notification-queue.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private queueService: NotificationQueueService) {
    }

    notify(options: NotificationOptions) {
        this.queueService.addNotification(options);
    }

}

