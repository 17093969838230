import { Action, createReducer, on } from '@ngrx/store';
import * as roundActions from './round.actions';

export interface RoundState {
  roundNumber: number;
  type: string;
  questions?: any;
  answers?: any;
  pointsToSteal: number;
}

export const initialRoundState: RoundState = {
  roundNumber: 0,
  type: null,
  questions: null,
  answers: null,
  pointsToSteal: null
};

const _roundReducer = createReducer(initialRoundState,
  on(roundActions.startRound, (state, payload) => ({...state, ...payload.payload, questions: null})),
  on(roundActions.showQuestions, (state, payload) => ({...state, questions: payload.payload})),
  on(roundActions.updateAnswers, (state, payload) => ({...state, answers: payload.payload})),
  on(roundActions.clearQuestions, (state, payload) => ({...state, questions: null})),
  on(roundActions.clearRoundState, () => initialRoundState),
  on(roundActions.setPointsToSteal, (state, payload) => ({...state, pointsToSteal: payload.payload}))
);

export function roundReducer(state: RoundState | undefined, action: Action) {
  return _roundReducer(state, action);
}
