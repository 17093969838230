import {Directive, HostListener, OnInit} from '@angular/core';
import {LayoutFacade} from '../../../+state/layout.facade';

@Directive({
  selector: '[updateLayout]'
})
export class UpdateLayoutDirective implements OnInit {

  constructor(private layoutFacade: LayoutFacade) {
  }

  ngOnInit(): void {
    this.layoutFacade.resizeWindow({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.layoutFacade.resizeWindow({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // this.store.dispatch(new fromStore.ScrollWindow({
    //     offsetY: window.scrollY
    // }));
  }

}
