import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import * as notificationActions from './notification.actions';
import {NotificationService} from '@qnm/services/notification/notification.service';
import {Notification} from '@qnm/interfaces/notification/notification.interface';
import {of} from 'rxjs';

@Injectable()
export class NotificationEffects {
  constructor(
    private notificationService: NotificationService,
    private actions$: Actions) {
  }

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(ofType(notificationActions.getNotificationsRequest),
      exhaustMap(() => this.notificationService.getNotifications()
        .pipe(
          map((notifications: Notification[]) => notificationActions.getNotificationsSuccess({notifications})),
          catchError(err => of(notificationActions.getNotificationsFailure({error: err})))
        ))
    )
  );

  approveNotification$ = createEffect(() =>
    this.actions$.pipe(ofType(notificationActions.approveNotificationRequest),
      exhaustMap((data: any) => this.notificationService.approveNotification(data)
        .pipe(
          // tslint:disable-next-line:no-shadowed-variable
          map((resp) => notificationActions.approveNotificationSuccess({data})),
          catchError(err => of(notificationActions.approveNotificationFailure({error: err})))
        ))
    )
  );
}
