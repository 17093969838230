import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) {
  }

  mapLanguages(langs) {
    let mappedLangs = [];
    this.localize.parser.locales.forEach(d => {
      mappedLangs.push({
          key: d,
          label: 'language.'+d
        })
    })

    return mappedLangs;
  }

  deepMerge(target: any, ...source: Array<any>) {

    let output = Object.assign({}, target);

    source.forEach(item => {
      Object.keys(item).forEach((key: any) => {
        if (this.isObject(item[key])) {
          if (!(key in target)) {
            Object.assign(output, {[key]: item[key]});
          } else {
            output[key] = this.deepMerge(target[key], item[key]);
          }
        } else {
          Object.assign(output, {[key]: item[key]});
        }
      });
    });

    return output;
  }

  isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  decodeHTML(html: string) {

    const txt = document.createElement('textarea');

    txt.innerHTML = html;

    return txt.value;
  }

  copyToClipboard(inputId: string) {

    const input = <HTMLInputElement>document.querySelector(`#${inputId}`);

    input.select();
    document.execCommand('Copy');
  }

  calculateCashAnimateFrom(cash) {
    if (cash > 200) {
      return cash - 200;
    }

    if (cash <= 200) {
      const proc = cash * 10 / 100;
      return cash - proc;
    }
  }


  copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement('textarea');
    el.style.position = 'absolute';
    el.style.left = '-999999px';
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);

  }

  hasError(error: any, field: any) {
    if (error && error.hasOwnProperty('violations')) {
      const fieldError = error.violations.find((d: any) => d.propertyPath === field);
      return fieldError ? fieldError : false;
    }

    if (error && !error.hasOwnProperty('violations')) {
      return error;
    }

    return false;
  }

  getDictionary() {
    return this.http.get(environment.apiUrl + '/v1/dictionary/entry', {withCredentials: false}).pipe(
      map((response: any) => response));
  }

  getYears(back: number): number[] {
    const year = new Date().getFullYear();
    return Array.from({length: back}, (v, i) => year - back + i + 1).reverse();
  }
}
