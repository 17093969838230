import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationUtilsService {

    constructor() {
    }

    /**
     * Generates a unique ID for control.
     * For example, id is used to bind label and control.
     * @return Unique ID for a control.
     */
    generateUniqueId(): string {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    }

}
