import {createAction, props} from '@ngrx/store';
import {Notification} from '@qnm/interfaces/notification/notification.interface';

export const getNotificationsRequest = createAction('[Notification] Get Notifications Request');
export const getNotificationsSuccess = createAction('[Notification] Get notifications Success', props<{ notifications: Notification[] }>());
export const getNotificationsFailure = createAction('[Notification] Get notifications Failure', props<{ error: any }>());

export const approveNotificationRequest = createAction('[Notification] Approve Notification Request', props<{ notificationId: string }>());
export const approveNotificationSuccess = createAction('[Notification] Approve Notification Success', props<{ data: any }>());
export const approveNotificationFailure = createAction('[Notification] Approve Notification Failure', props<{ error: any }>());
