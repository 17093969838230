import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as actions from '@qnm/+state/activity-stream/active-stream.actions';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ActivityStreamService} from '@qnm/services/activity-stream/activity-stream.service';
import {loadActivitiesFailure, loadActivitiesSuccess} from '@qnm/+state/activity-stream/active-stream.actions';
import {GetActivityStreamResponseInterface} from '@qnm/interfaces/activity-stream/GetActivityStreamResponse.interface';

@Injectable()
export class ActivityStreamEffects {
  constructor(
    private actions$: Actions,
    private activityStreamService: ActivityStreamService
  ) {
  }

  loadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadActivities),
      exhaustMap(() => this.activityStreamService.getActivityStream()
        .pipe(
          map((response: GetActivityStreamResponseInterface) => actions.loadActivitiesSuccess({activities: response.results})),
          catchError(err => of(actions.loadActivitiesFailure({error: err})))
        )
      )
    )
  );

}

