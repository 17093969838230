export const environment = {
  production: true,
  apiUrl: 'https://api.sportowequizy.pl',
  wsUrl: 'wss://ws.sportowequizy.pl',
  gameWsUrl: 'wss://game.sportowequizy.pl',
  awardsUrl: 'https://sportowequizy.pl/assets/awards',
  tenantId: '08e6d7c9-686c-4eb5-9536-09fd9ff321df',
  gtmId: null,
firebase: {
    apiKey: 'AIzaSyD83YZiUZPCR94NmkaR7uwfauLj9oRpJqw',
    authDomain: 'qnm-develop.firebaseapp.com',
    projectId: 'qnm-develop',
    storageBucket: 'qnm-develop.appspot.com',
    messagingSenderId: '801720091615',
    appId: '1:801720091615:web:73d1e8f870265f2a117a6a',
    measurementId: 'G-Q44L6QC79L',
    vapidKey: 'BMhk__yiiCyQTGqxGL53bsr_r2PlsyL7KwKg6ebAXW1hIKPTsjNU-AdvHXOXzFYQ8rBvgTAaFg-DlT9gOEEYkPQ'
  }
};

