import {createAction, props} from '@ngrx/store';
import {ActivityInterface} from '@qnm/interfaces/activity-stream/Activity.interface';

export const disableActivity = createAction('[ACTIVITY STREAM] Disable activity', props <{ activityId: string }>());

export const loadActivities = createAction('[ACTIVITY STREAM] Load activities');

export const loadActivitiesSuccess = createAction(
  '[ACTIVITY STREAM] Load activities success', props<{ activities: ActivityInterface[] }>());

export const loadActivitiesFailure = createAction('[ACTIVITY STREAM] Load activities failure', props<{ error: any }>());

export const pushActivity = createAction('[ACTIVITY STREAM] Push activity', props<{ activity: ActivityInterface }>());
