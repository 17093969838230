import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './active-stream.actions';
import {ActivityInterface} from '@qnm/interfaces/activity-stream/Activity.interface';

export interface ActivityStreamState {
  activities: Array<ActivityInterface>;
}

export const initialState: ActivityStreamState = {
  activities: new Array<ActivityInterface>(),
};

const _activityStreamReducer = createReducer(initialState,
  on(actions.disableActivity, (state, {activityId}) => {
    state.activities.find(k => k.id === activityId).joinGameDisabled = true;
    return {...state};
  }),
  on(actions.loadActivitiesSuccess, (state, {activities}) => {
    return {...state, activities};
  }),
  on(actions.pushActivity, (state, {activity}) => {
    const newActivities = [...state.activities];
    newActivities.unshift(activity);
    newActivities.pop();
    return {...state, activities: newActivities};
  })
);

export function activityStreamReducer(state: ActivityStreamState | undefined, action: Action) {
  return _activityStreamReducer(state, action);
}
