import { Action, createReducer, on } from '@ngrx/store';
import * as lobbyFilterActions from './lobby-filters.actions';

export interface LobbyFiltersState {
  buyInFrom: string | number;
  buyInTo: string | number;
  maxPlayersFrom: string | number;
  maxPlayersTo: string | number;
  category: [];
  sortColumn: string;
  sortType: string;
  hideEmptyWaitingRooms: boolean;
}


export const initialState: LobbyFiltersState = {
  buyInFrom: null,
  buyInTo: null,
  maxPlayersFrom: null,
  maxPlayersTo: null,
  category: [],
  sortColumn: null,
  sortType: 'asc',
  hideEmptyWaitingRooms: false
};

const _lobbyFiltersReducer = createReducer(
  initialState,
  on(lobbyFilterActions.clearFilters, state => initialState),
  on(lobbyFilterActions.setFilters, (state, action: any) => {
    return ({...state, ...action.payload});
  }),
  on(lobbyFilterActions.setSort, (state, action: any) => {

    if (!state.sortColumn || state.sortColumn !== action.payload.sortColumn) {
      return ({...state, ...action.payload, sortType: 'desc'});
    }

    if (state.sortColumn === action.payload.sortColumn && state.sortType === 'desc') {
      return ({...state, ...action.payload, sortType: 'asc'});
    }

    if (state.sortColumn === action.payload.sortColumn && state.sortType === 'asc') {
      return ({...state, sortColumn: null, sortType: 'desc'});
    }

  }),
  on(lobbyFilterActions.toggleCategory, (state: any, action: any) => {

    let category = [...state.category];

    if (category.indexOf(action.payload) > -1) {
      category = category.filter(d => d != action.payload);
    } else {
      category = [...category, action.payload];
    }

    return ({...state, category});
  }),
  on(lobbyFilterActions.setHideEmptyWaitingRooms, (state: any, action: any) => {
    return ({...state, hideEmptyWaitingRooms: action.payload});
  })
);

export function lobbyFiltersReducer(state: LobbyFiltersState | undefined, action: Action) {
  return _lobbyFiltersReducer(state, action);
}
