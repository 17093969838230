import {Injectable} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  CanActivate
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';


@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate {
  constructor(private router: Router,
              private route: ActivatedRoute,
              private localize: LocalizeRouterService,
              private authService: AuthService) {

  }

  canActivate(): Observable<boolean> {

    if (!this.authService.isLoggedIn()) {
      return of(true);
    }
    this.router.navigate([this.localize.translateRoute('/')]);
    return of(false);
  }

}
