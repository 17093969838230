import { Injectable } from '@angular/core';
import { UtilsService } from 'qnm-shared/_services/utils.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as dictActions from './dictionary.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DictionaryEffects {
  constructor(private utilsService: UtilsService, private actions$: Actions,) {
  }

  loadDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dictActions.loadDictionary),
      exhaustMap(() => this.utilsService.getDictionary()
        .pipe(
          map(data => dictActions.loadDictionarySuccess({payload: data})),
          catchError(err => of(dictActions.loadDictionaryFailure({error: err})))
        )
      )
    )
  )
}
