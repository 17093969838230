import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as gameActions from './game.actions';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {GameService} from '@qnm/modules/_game-shared/services/game.service';
import * as tournamentActions from '@qnm/modules/_instant-tournament/+state/tournament.actions';

@Injectable()
export class GameEffects {
  constructor(
    private actions$: Actions,
    private gameService: GameService
  ) {
  }

  leaveTournamentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gameActions.leaveTournamentRequest),
      exhaustMap((data) => this.gameService.leaveTournament(data.id)
        .pipe(
          map(() => gameActions.setTournamentId(null))
        )
      )
    )
  );

  leaveGame$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gameActions.leaveGame),
      exhaustMap((data) => this.gameService.leaveGame(data.id)
        .pipe(
          map(() => gameActions.leaveGameSuccess())
        )
      )
    )
  );

  getTournamentRestore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gameActions.getRestoreTournament),
      exhaustMap((payload) => this.gameService.getRestoreTournament(payload.id)
        .pipe(
          map((ladder: any) => gameActions.getRestoreTournamentSuccess({payload: ladder})),
          catchError(err => of(gameActions.getRestoreTournamentFailure({error: err})))
        )
      )
    )
  );
}
