import { createAction, props } from '@ngrx/store';

export const startGame = createAction('[Game] Start game', props<{ game: any }>());
export const leaveGame = createAction('[Game] Leave game', props<{ id: any }>());
export const leaveGameSuccess = createAction('[Game] Leave game success');

export const updatePlayersStatuses = createAction('[Game] Update players statuses', props<{ statuses: any }>());

export const updateNumberOfStages = createAction('[Game] Update numberOfStages', props<{ numberOfStages: any }>());

export const clearActiveGameState = createAction('[Clear Active Game] Clear active game');
export const showSummary = createAction('[Show Summary] Show', props<{summary: any}>());

export const setTournamentId = createAction('[Game] Set Tournament Id', props<{ id: any }>());
export const setTournament = createAction('[Game] Set Tournament Data', props<{ payload: any }>());

export const leaveTournamentRequest = createAction('[Game] Leave Tournament Request', props<{ id: any }>());
export const leaveTournamentRequestSuccess = createAction('[Game] Leave Tournament Success', props<{ id: any }>());

export const getRestoreTournament = createAction('[Tournament] Get Restore Tournament', props<{ id: string }>());
export const getRestoreTournamentSuccess = createAction('[Tournament] Get Restore Tournament Success', props<{ payload: any }>());
export const getRestoreTournamentFailure = createAction('[Tournament] Get Restore Tournament Failure', props<{ error: any }>());

