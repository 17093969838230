import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient) {
  }

  getWallets(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/wallets', {withCredentials: true}).pipe(
      map((response: any) => response.items));
  }

  getBonuses(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/bonuses', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getFreeCoinsPackages(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/purchase/fun-coin-package', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getQuizCoinsPackages(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/purchase/quiz-coin-package', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getQuizCoinsResaleConfig(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/resale/quiz-coin-resale/config', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getQuizCoinsConfig(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/purchase/quiz-coin-purchase/config', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getBankAccountConfig(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/config/get/bank_transfer_data', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getPaymentMethods(): Observable<any> {
    return this.http.get(environment.apiUrl + '/v1/purchase/payment-provider', {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  purchaseFreeCoinsPackages(payload): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/purchase/fun-coin-purchase', payload,{withCredentials: true}).pipe(
      map((response: any) => response));
  }

  purchaseQuizCoinsPackages(payload): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/purchase/quiz-coin-purchase', payload,{withCredentials: true}).pipe(
      map((response: any) => response));
  }

  withdrawQuizCoins(payload): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/resale/quiz-coin-resale', payload,{withCredentials: true}).pipe(
      map((response: any) => response));
  }

  getPayProStatus(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + `/v1/purchase/pay-pro/${id}/status`, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  checkQuizCoinsTotalPurchasePlayerExceeded(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/v1/purchase/quiz-coin-purchase/validate`, payload, {withCredentials: true}).pipe(
      map((response: any) => response));
  }

  checkFreeCoinsTotalPurchasePlayerExceeded(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/v1/purchase/fun-coin-purchase/validate`, payload, {withCredentials: true}).pipe(
      map((response: any) => response));
  }
}
