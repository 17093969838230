import { createAction, props } from '@ngrx/store';

export const setFilters = createAction(
  '[Lobby filters] Set filters',
  props<{payload: any}>()
);

export const toggleCategory = createAction(
  '[Lobby filters] Set Category filter',
  props<{payload: any}>()
);

export const clearFilters = createAction(
  '[Lobby filters] Disable filters'
);

export const setSort = createAction(
  '[Lobby sort] set sort',
  props<{payload: any}>()
);

export const setHideEmptyWaitingRooms = createAction(
  '[Lobby filters] hide empty waiting rooms',
  props<{payload: boolean}>()
);
