import { Action, createReducer, on } from '@ngrx/store';
import * as tokenActions from './token.actions';

export interface TokenState {
  isLoading: boolean;
  data: any;
  error?: any;
}

export const initialState: TokenState = {
  isLoading: false,
  data: null,
  error: null
};

const _tokenReducer = createReducer(
  initialState,
  on(tokenActions.generateToken, state => ({...state, isLoading: true, error: null, data: null})),
  on(tokenActions.generateTokenSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    error: null,
    data: payload
  })),
  on(tokenActions.generateTokenFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    error: error,
    data: null
  })),
  on(tokenActions.regenerateToken, state => ({...state, isLoading: true, error: null, data: null})),
  on(tokenActions.regenerateTokenSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    error: null,
    data: payload
  })),
  on(tokenActions.regenerateTokenFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    error: error,
    data: null
  })),
);

export function tokenReducer(state: TokenState | undefined, action: Action) {
  return _tokenReducer(state, action);
}
