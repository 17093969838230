import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as dicActions from './dictionary.actions';
import {getDictById, getDictionary} from './dictionary.selectors';

@Injectable()
export class DictionaryFacade {

  dictionaries$ = this.store.pipe(select(getDictionary));
  getSingleDict$ = (id: string) => this.store.pipe(select(getDictById(id)));

  constructor(private store: Store<any>) {
  }

  loadDictionary() {
    this.store.dispatch(dicActions.loadDictionary());
  }
}
