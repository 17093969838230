import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthService } from 'qnm-auth/services/auth.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserFacade } from '@qnm/+state/user/user.facade';
import * as userActions from '@qnm/+state/user/user.actions';
import { UserState } from '@qnm/+state/user';


@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private router: Router,
              private localize: LocalizeRouterService,
              private userFacade: UserFacade,
              private store: Store<any>,
              private authService: AuthService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      return this.checkStore().pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
    } else {
      // not logged in so redirect to login page
      this.router.navigate([this.localize.translateRoute('/auth/login')]);
      return of(false);
    }
  }


  checkStore(): Observable<UserState> {
    return this.userFacade.userState$.pipe(
      tap(loaded => {
        if ((!loaded.isLoggedIn && !loaded.isLoading)) {
          this.store.dispatch(userActions.loadUser());
        }
      }),
      filter(loaded => loaded.isLoggedIn),
      take(1)
    );
  }
}
