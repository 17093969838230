import { Action, createAction, props } from '@ngrx/store';

export enum LayoutActionTypes {
  CollapseSidebar = '[Layout] Collapse Sidebar',
  ResizeWindow = '[Layout] Resize window',
  ToggleSideBarMenu = '[Layout] Toggle SideBarMenu',
  CloseSideBarMenu = '[Layout] Close SideBarMenu',
  OpenSideBarMenu = '[Layout] Open SideBarMenu',
  SetBodyClass = '[SetBodyClass] Set class',
  ChangeTheme = '[ChangeTheme] Change theme',
  ShowLoader = '[Loader] Show',
  HideLoader = '[Loader] Hide',
  HideNavigation = '[NavBar] Hide',
  ShowNavigation = '[NavBar] Show',
  ToggleNavigation = '[NavBar] Toggle',
  ToggleIsFreeCoins = '[Coin Switcher] toggle'
}

export class ChangeTheme implements Action {
  readonly type = LayoutActionTypes.ChangeTheme;

  constructor(public payload: any) {
  }
}

export class SetBodyClass implements Action {
  readonly type = LayoutActionTypes.SetBodyClass;

  constructor(public payload: any) {
  }
}

export class CollapseSidebar implements Action {
  readonly type = LayoutActionTypes.CollapseSidebar;

  constructor(public payload: any) {
  }
}

export class ResizeWindow implements Action {
  readonly type = LayoutActionTypes.ResizeWindow;

  constructor(public payload: any) {
  }
}

export class ToggleSideBarMenu implements Action {
  readonly type = LayoutActionTypes.ToggleSideBarMenu;
}

export class CloseSideBarMenu implements Action {
  readonly type = LayoutActionTypes.CloseSideBarMenu;
}

export class OpenSideBarMenu implements Action {
  readonly type = LayoutActionTypes.OpenSideBarMenu;
}

export class HideNavigation implements Action {
  readonly type = LayoutActionTypes.HideNavigation;
}

export class ShowNavigation implements Action {
  readonly type = LayoutActionTypes.ShowNavigation;
}

export class ToggleNavigation implements Action {
    readonly type = LayoutActionTypes.ToggleNavigation;
}

export class ShowLoader implements Action {
  readonly type = LayoutActionTypes.ShowLoader;
}

export class HideLoader implements Action {
  readonly type = LayoutActionTypes.HideLoader;
}

export class ToggleIsFreeCoins implements Action {
  readonly type = LayoutActionTypes.ToggleIsFreeCoins;
}


export type LayoutAction =
  CollapseSidebar
  | ResizeWindow
  | ToggleSideBarMenu
  | CloseSideBarMenu
  | OpenSideBarMenu
  | SetBodyClass
  | ChangeTheme
  | ShowNavigation
  | HideNavigation
  | ShowLoader
  | HideLoader
  | ToggleIsFreeCoins
  | ToggleNavigation
  ;

export const fromLayoutActions = {
  CollapseSidebar,
  ResizeWindow,
  ToggleSideBarMenu,
  CloseSideBarMenu,
  OpenSideBarMenu,
  SetBodyClass,
  ChangeTheme,
  HideNavigation,
  ShowNavigation,
  ShowLoader,
  HideLoader,
  ToggleIsFreeCoins
};
