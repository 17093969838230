import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {WalletService} from '@qnm/services/wallet/wallet.service';
import * as walletActions from './wallet.actions';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NotificationService} from 'qnm-shared/modules/notifications/services/notification/notification.service';

@Injectable()
export class WalletEffects {
  constructor(private actions$: Actions,
              private notificationService: NotificationService,
              private walletService: WalletService) {
  }


  loadWallets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletActions.getWallets),
      exhaustMap(() => this.walletService.getWallets()
        .pipe(
          map(wallet => walletActions.getWalletsSuccess({wallets: wallet})),
          catchError(err => of(walletActions.getWalletsFailure({error: err})))
        )
      )
    )
  );

  getPayProStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletActions.getPayProStatus),
      exhaustMap((payload) => this.walletService.getPayProStatus(payload.paymentId)
        .pipe(
          map(resp => walletActions.getPayProStatusSuccess({status: resp.status})),
          catchError(err => of(walletActions.getPayProStatusFailure({error: err})))
        )
      )
    )
  );

  getPayProStatusSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(walletActions.getPayProStatusSuccess),
        tap((payload: { status: string }) => {
          this.notificationService.notify({
            type: payload.status === 'SUCCEEDED' ? 'success' : 'danger',
            message: payload.status === 'SUCCEEDED' ? 'buy.quizcoins.success' : 'buy.quizcoins.error'
          });
        })
      ),
    {dispatch: false}
  );


  loadBonuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletActions.getBonuses),
      exhaustMap(() => this.walletService.getBonuses()
        .pipe(
          map(bonuses => walletActions.getBonusesSuccess({bonuses})),
          catchError(err => of(walletActions.getBonusesFailure({error: err})))
        )
      )
    )
  );
}
